import { PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { RootState } from "./rootReducer";

export const persistConfig: PersistConfig<RootState> = {
  key: "root",
  storage,
  blacklist: [
    "user",
    "notifications",
    "UI",
    "mediaPlayer",
    "api",
    "registration",
    "projectReports",
    "chatSidebar",
  ],
};

export const authPersistConfig = {
  key: "auth",
  storage,
  blacklist: ["error"],
};
