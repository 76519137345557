import { Info } from "@mui/icons-material";
import { FC } from "react";
import { Tooltip } from "react-tooltip";
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import { getTheme } from "ui/theme/theme";
import { defaultBrandingColors } from "domain/common";

interface HelperTooltipProps {
  id: string;
  title?: string;
  content?: string | JSX.Element | JSX.Element[];
  place?: "top" | "right" | "bottom" | "left";
}

export const HelperTooltip: FC<HelperTooltipProps> = ({
  id,
  title,
  content,
  place = "right",
}) => {
  const { customColors } = useTheme();

  return (
    <>
      <Info
        data-tooltip-id={id}
        className="text-gray-300 cursor-pointer hover:text-gray-400 transition-colors ease-in"
        style={{ fontSize: 18 }}
      />
      <Tooltip
        id={id}
        place={place}
        className="!bg-white z-50 !shadow-depth-2 !border-2 !border-solid !border-gray-200 !rounded-xl !text-left !pb-4 !pt-3 !px-4 !opacity-100 !flex"
        style={{ backgroundColor: "white", color: "inherit" }}
        arrowColor={customColors.gray[200]}
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={getTheme(defaultBrandingColors)}>
            <CssBaseline />
            <div
              style={{ textTransform: "none" }}
              className="flex max-w-[200px] flex-col w-full"
            >
              {title && (
                <Typography
                  className=" text-gray-500 font-semibold m-0"
                  variant="subtitle1"
                >
                  {title}
                </Typography>
              )}
              <Typography
                variant="subtitle2"
                className="mt-2 text-gray-400 whitespace-normal"
              >
                {content}
              </Typography>
            </div>
          </ThemeProvider>
        </StyledEngineProvider>
      </Tooltip>
    </>
  );
};

export default HelperTooltip;
