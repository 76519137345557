import {
  DatePickerProps as MuiDatePickerProps,
  DatePicker as MuiDatePicker,
} from "@mui/x-date-pickers/DatePicker";
import { FormInput } from "ui/components";
import { InputProps } from "@mui/material/Input";
import pick from "lodash/pick";

interface Props {
  inputRef: any;
  error?: boolean;
}

const DatePicker = function DatePicker({
  inputRef,
  error,
  ...props
}: Props & Omit<MuiDatePickerProps, "renderInput">) {
  return (
    <div>
      <MuiDatePicker
        {...props}
        mask="__/__/____"
        renderInput={(textFieldProps) => {
          const InputProps = textFieldProps.InputProps as InputProps;
          const params = pick(textFieldProps, [
            "inputRef",
            "label",
            "inputProps",
            "error",
            "disabled",
          ]) as InputProps;

          const _props = {
            ...InputProps,
            ...params,
            error,
          };

          return <FormInput ref={inputRef} {..._props} />;
        }}
      />
    </div>
  );
};

export default DatePicker;
