export const applyBoldToMatch = (text: string, term: string) => {
  if (!term) return text;

  const parts = text.split(new RegExp(`(${term})`, "gi"));

  return parts.map((part, index) =>
    part.toLowerCase() === term.toLowerCase() ? (
      <strong key={index}>{part}</strong>
    ) : (
      part
    )
  );
};
