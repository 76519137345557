import FormControl from "@mui/material/FormControl";
import Input, { InputProps } from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import React, { ReactElement } from "react";
import { HelperTooltip } from "../HelperTooltip";
import { clsxm } from "application/utils";

export interface FormInputProps extends InputProps {
  label?: string;
  error?: boolean;
  tooltip?: {
    id: string;
    title?: string;
    content: string | ReactElement;
  };
  required?: boolean;
  labelClassName?: string;
}

const FormInput = React.forwardRef(
  (
    {
      label,
      error = false,
      tooltip,
      required,
      labelClassName,
      ...props
    }: FormInputProps,
    ref: React.ForwardedRef<FormInputProps>
  ) => {
    return (
      <FormControl fullWidth error={error} variant="standard">
        {label && (
          <InputLabel
            disableAnimation
            shrink
            className={clsxm(
              "relative flex items-center pb-3 box-content h-3 space-x-2 overflow-visible",
              labelClassName
            )}
          >
            <span>{label}</span>
            {required && <span className="text-danger-300">*</span>}
            {tooltip && (
              <HelperTooltip
                id={tooltip.id}
                title={tooltip.title}
                content={tooltip.content}
              />
            )}
          </InputLabel>
        )}
        <div className="w-full flex h-auto relative">
          <Input
            inputRef={ref}
            disableUnderline
            {...props}
            className={clsxm("w-full", props.className)}
            style={{ marginTop: "unset" }}
          />
        </div>
      </FormControl>
    );
  }
);

FormInput.displayName = "FormInput";

export default FormInput;
