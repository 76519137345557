import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAssistantChatMessage } from "ui/components/AssistantFloatingChat";

export interface IMessageGroup {
  moduleId: string;
  moduleName: string;
  selection: string | null;
  messages: IAssistantChatMessage[];
}

export type ChatSidebarPayload = null | {
  moduleId: string;
  reportId: string;
};

export interface ChatSidebarState {
  isOpen: boolean;
  isSubmitting: boolean;
  messageGroups: IMessageGroup[];
  payload: ChatSidebarPayload;
  generatingModuleId: string | null;
  threadMap: Record<string, string>;
}

const initialState: ChatSidebarState = {
  isOpen: false,
  isSubmitting: false,
  generatingModuleId: null,
  messageGroups: [],
  payload: null,
  threadMap: {},
};

const chatSidebarSlice = createSlice({
  name: "chatSidebar",
  initialState,
  reducers: {
    openChatSidebar(
      state: ChatSidebarState,
      { payload }: PayloadAction<ChatSidebarPayload>
    ) {
      state.isOpen = true;
      state.payload = payload;
    },
    toggleChatSidebar(
      state: ChatSidebarState,
      { payload }: PayloadAction<boolean>
    ) {
      state.isOpen = payload;
    },
    setMessageGroups(
      state: ChatSidebarState,
      { payload }: PayloadAction<IMessageGroup[]>
    ) {
      state.messageGroups = payload;
    },
    addThreadToThreadMap(
      state: ChatSidebarState,
      { payload }: PayloadAction<{ moduleId: string; threadId: string }>
    ) {
      state.threadMap = {
        ...state.threadMap,
        [payload.moduleId]: payload.threadId,
      };
    },
    setSubmitting(
      state: ChatSidebarState,
      { payload }: PayloadAction<boolean>
    ) {
      state.isSubmitting = payload;
    },
    setGeneratingModuleId(
      state: ChatSidebarState,
      { payload }: PayloadAction<string | null>
    ) {
      state.generatingModuleId = payload;
    },
    resetChatBar() {
      return initialState;
    },
  },
});

export const {
  toggleChatSidebar,
  resetChatBar,
  setMessageGroups,
  openChatSidebar,
  addThreadToThreadMap,
  setSubmitting,
  setGeneratingModuleId,
} = chatSidebarSlice.actions;

export default chatSidebarSlice.reducer;
