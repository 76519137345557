import projectService from "services/project.service";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { IProject } from "types/project.service";
import useTypedSelector from "../useTypeSelector";
import { selectCurrentProjectId } from "redux/ProjectReports/reducer";

export const useCurrentProject = (
  config?: SWRConfiguration<IProject>
): SWRResponse<IProject> => {
  const alias = useTypedSelector(selectCurrentProjectId);

  const { isValidating, isLoading, data, mutate, error } = useSWR(
    alias && `/project/${alias}`,
    () => {
      return projectService.getProject(alias);
    },
    config
  );

  return {
    isValidating,
    data,
    mutate,
    error,
    isLoading,
  };
};
