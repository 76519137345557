import { Check, Close } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import { FC, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AutocompleteController } from "ui/components";

interface UserAutocompleteFormProps {
  users?: Array<{
    userId: string;
    fullName: string;
    email?: string;
    imageUrl?: string;
  }>;
  isLoading?: boolean;
  onSubmit: (userId: string) => Promise<void>;
  onCancel: () => void;
  submitButtonText?: string;
}

interface FormInputs {
  selectedUser: Option | null;
}

const defaultValues: FormInputs = {
  selectedUser: null,
};

export const UserAutocompleteForm: FC<UserAutocompleteFormProps> = ({
  users = [],
  isLoading,
  onSubmit,
  onCancel,
  submitButtonText = "Submit",
}) => {
  const { control, reset, handleSubmit, watch } = useForm<FormInputs>({
    defaultValues,
  });
  const watchSelectedUser = watch("selectedUser");

  const handleFormSubmit: SubmitHandler<FormInputs> = async ({
    selectedUser,
  }) => {
    if (selectedUser?.value) {
      await onSubmit(selectedUser.value);
    }
    reset(defaultValues);
  };

  const userOptions = useMemo<Option[]>(() => {
    if (!users) return [];

    return users.map((user) => ({
      label: `${user.fullName || ""} (${user.email})`,
      value: user.userId,
    }));
  }, [users]);

  return (
    <form className="space-y-8" onSubmit={handleSubmit(handleFormSubmit)}>
      <AutocompleteController
        options={userOptions}
        name="selectedUser"
        freeSolo
        control={control}
        label="Select user"
        showError
        loading={isLoading}
      />
      <div className="mt-auto flex space-x-2 pt-6">
        <Button
          onClick={onCancel}
          endIcon={<Close />}
          variant="outlined"
          className="w-full"
        >
          Cancel
        </Button>
        <LoadingButton
          disabled={!watchSelectedUser}
          variant="contained"
          endIcon={<Check />}
          className="w-full"
          type="submit"
          loading={isLoading}
        >
          {submitButtonText}
        </LoadingButton>
      </div>
    </form>
  );
};
