import { Tab } from "@mui/material";
import { excludeItemsOnBullpen } from "bullpen/utils/excludeItemsWhenBullpen";
import { Fragment, Suspense } from "react";
import { generatePath, Link, Outlet } from "react-router-dom";
import { LINK_TABS_HEIGHT, LinkTabs } from "ui/components/LinkTabs";
import { useCurrentPath } from "ui/hooks";
import * as paths from "ui/Router/paths";

const allItems: Array<{
  label: string;
  to: string;
  subPaths?: string[];
}> = [
  {
    label: "Templates",
    to: paths.assistantTemplates,
  },
  {
    label: "Cache",
    to: paths.assistantCache,
  },
  {
    label: "Conversations",
    to: paths.assistantConversations,
  },
  {
    label: "Threads",
    to: paths.assistantThreads,
  },
  {
    label: "Email conversation",
    to: paths.assistantEmailConversations,
    subPaths: [paths.assistantEmailRecipients],
  },
  {
    label: "Settings",
    to: paths.assistantSettings,
  },
];

const excludeWhenBullpenItems = ["Templates", "Cache"];

const excludeWhenBullpen = excludeItemsOnBullpen<{ label: string }>(
  excludeWhenBullpenItems.map((label) => ({ label })),
  "label"
);

const items = allItems.filter(excludeWhenBullpen);

const AssistantManagementWrapper = () => {
  const currentPath = useCurrentPath();

  const currentTabValue =
    items.find(({ to, subPaths = [] }) =>
      [to, ...subPaths].includes(currentPath || "")
    )?.to || items[0]?.to;

  return (
    <div className="m9-container">
      <LinkTabs value={currentTabValue}>
        {items.map(({ label, to }, index) => {
          return (
            <Tab
              key={index}
              value={to}
              className="text-[16px] py-6 px-2 font-bold capitalize hover:text-primary-400 transition-colors ease-in"
              component={Link}
              to={generatePath("/" + to)}
              label={label}
            />
          );
        })}
      </LinkTabs>

      <div
        className="m9-container grow flex flex-col"
        style={{ height: `calc(100% - ${LINK_TABS_HEIGHT})` }}
      >
        <Suspense fallback={<Fragment />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};

export default AssistantManagementWrapper;
