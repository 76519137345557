import {
  Autocomplete,
  Avatar,
  Chip,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { clsxm, isArrayOfStrings } from "application/utils";
import { Warning } from "@mui/icons-material";
import { useChipStyles } from "ui/pages/DataRoom/DataRoomFilePermissions/hooks/useChipStyles";
import { validate } from "email-validator";

interface ProjectSharingInputProps {
  value?: string[];
  onChange?: (value: string[]) => void;
}

export const ProjectSharingInput = ({
  value,
  onChange,
}: ProjectSharingInputProps) => {
  const { customColors } = useTheme();
  const { getChipsStyles } = useChipStyles();

  return (
    <Autocomplete
      options={[]}
      freeSolo
      multiple
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            InputProps={{
              className: params.InputProps.className,
              startAdornment: params.InputProps.startAdornment,
            }}
            placeholder={value?.length ? undefined : "Enter contact emails"}
            className={clsxm(
              "w-full disabled:border disabled:border-solid disabled:border-gray-300"
            )}
          />
        );
      }}
      renderTags={(value, props) => {
        const val = value as unknown;
        const typedValue = isArrayOfStrings(value) ? (val as string[]) : [];

        return typedValue.map((option, index) => {
          const propsFlat = props({ index });
          const isEmail = validate(option.trim());
          return (
            <Chip
              label={option}
              {...propsFlat}
              key={propsFlat.key}
              sx={{
                ...getChipsStyles(),
                "& .MuiChip-avatar": {
                  ...(!isEmail && {
                    color: customColors.danger[600],
                    width: 20,
                  }),
                },
                "& .MuiChip-label": {
                  maxWidth: 300,
                },
              }}
              avatar={
                isEmail ? (
                  <Tooltip title={option}>
                    <Avatar alt={option} className="bg-slate-300" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Not a valid email address">
                    <Warning className="w-3 h-3" />
                  </Tooltip>
                )
              }
            />
          );
        });
      }}
      value={value}
      onChange={(_, value) => {
        if (isArrayOfStrings(value)) {
          onChange?.(value as string[]);
        }
      }}
    />
  );
};
