import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  StandardTextFieldProps,
  TextField,
} from "@mui/material";
import { clsxm } from "application/utils";
import clsx from "clsx";
import {
  ButtonHTMLAttributes,
  forwardRef,
  MouseEventHandler,
  ReactElement,
  useMemo,
} from "react";

interface SearchInputProps extends StandardTextFieldProps {
  buttonType?: ButtonHTMLAttributes<any>["type"];
  iconButtonClasses?: string;
  customIcon?: ReactElement;
  customButton?: ReactElement;
  loading?: boolean;
  onButtonClick?: MouseEventHandler;
  wrapperClassName?: string;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    {
      buttonType = "submit",
      size = "medium",
      onChange,
      onBlur,
      name,
      iconButtonClasses,
      InputProps,
      customIcon,
      customButton,
      loading,
      onButtonClick,
      disabled,
      wrapperClassName,
      ...props
    },
    ref
  ) => {
    const icon = useMemo(() => {
      if (loading) {
        return <CircularProgress size={16} className="mx-auto my-auto" />;
      }

      return customIcon || <Search />;
    }, [customIcon, loading]);

    return (
      <div className={clsxm("relative w-full text-inherit", wrapperClassName)}>
        <TextField
          className={clsx(
            "w-full disabled:border disabled:border-solid disabled:border-gray-300"
          )}
          size={size}
          onChange={onChange}
          {...props}
          inputProps={{
            ...props?.inputProps,
            "data-testid": "search-input",
            ref,
          }}
          InputProps={{
            readOnly: disabled,
            endAdornment: customButton || (
              <IconButton
                onClick={onButtonClick}
                className={clsx(
                  {
                    "h-8 w-8": size === "small",
                    "h-10 w-10": size === "medium",
                  },
                  "-my-2 relative bg-gray-200",
                  iconButtonClasses
                )}
                type={buttonType}
                disabled={loading || disabled}
              >
                {icon}
              </IconButton>
            ),
          }}
        />
      </div>
    );
  }
);
