import { config } from "config";
import { PlatformProjectName } from "domain/platform";
import { FC, ReactNode } from "react";
import { IProjectUserPermissions } from "types/project.service";
import { useProjectPermissions } from "ui/hooks";

interface PlatformSpecificElementElementProps {
  platform: PlatformProjectName;
  children: ReactNode;
}

interface PlatformSpecificElementWithPermissonCheckProps
  extends PlatformSpecificElementElementProps {
  checkPermission: (permissions: IProjectUserPermissions) => boolean;
}

export const PlatformSpecificElement: FC<
  PlatformSpecificElementElementProps
> = ({ platform, children }) => {
  return config.projectName === platform ? <>{children}</> : null;
};

export const PlatformSpecificElementWithPermissionCheck = ({
  platform,
  children,
  checkPermission,
}: PlatformSpecificElementWithPermissonCheckProps) => {
  const { permissions } = useProjectPermissions();

  if (!checkPermission(permissions)) {
    return null;
  }

  return <PlatformSpecificElement platform={platform} children={children} />;
};
