import { getSyndicationId } from "application/utils";
import { Auth } from "aws-amplify";
import axios, { AxiosHeaderValue } from "axios";
import { config } from "config";
import { signOut } from "redux/Auth/actions";
import { enqueueSnackbar as enqueueSnackbarAction } from "redux/Notifications/reducer";
import store from "redux/store";

const logOut = () => {
  const authorized = store.getState()?.auth?.authorized;

  if (authorized) {
    store.dispatch(
      enqueueSnackbarAction({
        message: "Authorization is expired!",
        options: { variant: "error", key: "auth-expired" },
      })
    );
    store.dispatch(signOut());
  }
};

const getSyndicationHeaders = () => {
  const syndicationId = getSyndicationId();

  if (!syndicationId) {
    return {} as any;
  }

  return { "partner-id": syndicationId };
};

const getAuthHeaders = async (): Promise<
  Partial<{ Authorization: string }>
> => {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    return { Authorization: `Bearer ${token}` };
  } catch (e) {
    return {};
  }
};

const createHeaders = async (extraHeaders: Record<string, string> = {}) => {
  const syndicationHeaders = getSyndicationHeaders();
  const authHeaders = await getAuthHeaders();
  return {
    ...syndicationHeaders,
    ...authHeaders,
    ...extraHeaders,
  };
};

const axiosConfig = {
  baseURL: config.api.baseUrl,
  headers: {
    ...getSyndicationHeaders(),
  },
};

const apiClient = axios.create(axiosConfig);
const uninterceptedApiClient = axios.create(axiosConfig);

apiClient.interceptors.request.use(
  async (config) => {
    const authHeaders = await getAuthHeaders();
    const headers: Record<string, string | AxiosHeaderValue> = {
      ...config.headers,
      ...authHeaders,
    };

    for (const key in headers) {
      config.headers.set(key, headers[key]);
    }

    return config;
  },
  (err) => {
    throw err;
  }
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;

    if (error.response) {
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        const { Authorization: token } = await getAuthHeaders();

        if (token) {
          apiClient.defaults.headers.common["Authorization"] = token;
          return apiClient(originalConfig);
        }
      }
      if (
        error.response.status === 401 &&
        error?.response?.data?.err?.msg === "Token expired"
      ) {
        logOut();
      }
      throw error;
    }
  }
);

const fetchWithAuth = async (url: string, options: RequestInit = {}) => {
  const baseUrl = config.api.baseUrl;
  const headers = await createHeaders(
    options.headers as Record<string, string>
  );
  return await fetch(baseUrl + "/" + url, { ...options, headers });
};

export { apiClient, fetchWithAuth, uninterceptedApiClient };
