import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IntrineoFilingImport {
  jobId: string;
  companyName: string;
}

interface DataRoomFilingsState {
  pendingFilingImports: IntrineoFilingImport[];
}

const initialState: DataRoomFilingsState = {
  pendingFilingImports: [],
};

const dataRoomSlice = createSlice({
  name: "dataRoom",
  initialState,
  reducers: {
    addFilingImport: (
      state: DataRoomFilingsState,
      action: PayloadAction<IntrineoFilingImport>
    ) => {
      state.pendingFilingImports.push(action.payload);
    },
    removeFilingImport: (
      state: DataRoomFilingsState,
      action: PayloadAction<string>
    ) => {
      state.pendingFilingImports = state.pendingFilingImports.filter(
        (filing) => filing.jobId !== action.payload
      );
    },
  },
});

export const { addFilingImport, removeFilingImport } = dataRoomSlice.actions;

export default dataRoomSlice.reducer;
