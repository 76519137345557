import {
  ChevronLeft,
  ChevronRight,
  Close,
  FilterList,
  PlaylistRemove,
} from "@mui/icons-material";
import {
  Button,
  Collapse,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { getSearchParams } from "application/searchParams";
import { AuditResourceType } from "domain/audit";
import { isEmpty, isString, isUndefined, uniqBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import auditService from "services/audit.service";
import lookupService from "services/lookup.service";
import useSWR from "swr";
import { IGetAuditFilter } from "types/audit.service";
import {
  AutocompleteController,
  FormSelectController,
  Loading,
  PageTitle,
} from "ui/components";
import { AuditActivityCard } from "ui/components/AuditActivityCard";
import { EmptyContentScreen } from "ui/components/EmptyContentScreen";
import { useLookupUsers, useTypedSelector } from "ui/hooks";
import resolver from "./AuditActivities.validation";

export interface FormFields {
  pageSize: number;
  fundId: Option | null;
  userId: Option | null;
  mediaId: Option | null;
  issuerId: Option | null;
  eventType: string[];
  resourceType: AuditResourceType;
}

interface PaginationFilter extends Omit<IGetAuditFilter, "lastEvaluatedKey"> {
  resourceType: AuditResourceType;
}

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

const AuditActivities = () => {
  const { user } = useTypedSelector((store) => store.auth);
  const { userId } = getSearchParams();
  const [paginationIndex, setPaginationIndex] = useState<number>(-1);
  const [paginationTokens, setPaginationTokens] = useState<
    Record<number, string>
  >({});
  const defaultIssuerOption = useMemo(
    () => ({
      value: user?.username,
      label: "Me",
    }),
    [user]
  );
  const [isCollapseFormOpen, setCollapseFormOpen] = useState(false);
  const [paginationFilter, setPaginationFilter] = useState<PaginationFilter>({
    issuerId: userId ? null : user?.username,
    resourceType: userId ? AuditResourceType.User : AuditResourceType.Fund,
    userId: userId,
    pageSize: 10,
  });
  const { data, error } = useSWR(
    [paginationIndex, paginationFilter, "/audit"],
    async (params) => {
      const [indexValue, filterValue] = params;
      const data = await auditService.getAudit({
        lastEvaluatedKey: paginationTokens[indexValue],
        eventType: filterValue?.eventType,
        fundId:
          filterValue?.resourceType === AuditResourceType.Fund
            ? filterValue?.fundId
            : undefined,
        mediaId:
          filterValue?.resourceType === AuditResourceType.Media
            ? filterValue?.mediaId
            : undefined,
        issuerId: filterValue?.issuerId,
        userId:
          filterValue?.resourceType === AuditResourceType.User
            ? filterValue?.userId
            : undefined,
        pageSize: filterValue.pageSize,
      });

      if (
        typeof paginationTokens[indexValue] === "string" ||
        indexValue === -1
      ) {
        setPaginationTokens((oldValue) => ({
          ...oldValue,
          [indexValue + 1]: data?.lastEvaluatedKey as string,
        }));
      }

      return data;
    }
  );
  const { control, watch, handleSubmit, formState, reset, setValue } =
    useForm<FormFields>({
      resolver: resolver,
      defaultValues: {
        userId: null,
        fundId: null,
        mediaId: null,
        pageSize: 10,
        eventType: [],
        issuerId: userId ? null : defaultIssuerOption,
        resourceType: userId ? AuditResourceType.User : AuditResourceType.Fund,
      },
    });
  const isLogsLoading = [data, error].every(isUndefined);
  const isEmptyResult =
    isUndefined(error) &&
    Array.isArray(data?.items) &&
    data?.items.length === 0;
  const resourceTypeValue = watch("resourceType");

  const { data: optionsDeals } = useSWR(
    "/lookup/deals",
    () => lookupService.getLookupDeals(),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const { data: optionsUsers } = useLookupUsers();
  const { data: optionsMedia } = useSWR("/lookup/media", () =>
    lookupService.getMediaList()
  );

  const dealOptions = useMemo(
    () =>
      optionsDeals?.response?.map((deal) => ({
        label: deal.fundName,
        value: deal.fundId,
      })) || [],
    [optionsDeals]
  );

  const mediaOptions = useMemo(
    () =>
      optionsMedia?.response?.map((media) => ({
        label: media.title,
        value: media.mediaId,
      })) || [],
    [optionsMedia]
  );

  const issuerOptions = useMemo(
    () =>
      uniqBy<Option>(
        [
          defaultIssuerOption,
          ...(optionsUsers?.map((u) => ({
            label: u?.fullName
              ? `${u?.fullName?.trim()} ${u?.email ? "(" + u?.email + ")" : ""}`
              : u.userId,
            value: u.userId,
          })) || []),
        ],
        "value"
      ),
    [defaultIssuerOption, optionsUsers]
  );

  useEffect(() => {
    if (userId && !formState.isDirty) {
      setValue(
        "userId",
        issuerOptions?.find(({ value }) => value === userId) || null
      );
    }
  }, [formState.isDirty, issuerOptions, setValue, userId]);

  const onSearch: SubmitHandler<FormFields> = useCallback(
    (values) => {
      const {
        eventType,
        fundId,
        issuerId,
        mediaId,
        resourceType,
        userId,
        pageSize,
      } = values;
      setPaginationTokens([]);
      setPaginationFilter({
        eventType,
        fundId: fundId?.value,
        issuerId: issuerId?.value,
        userId: userId?.value,
        mediaId: mediaId?.value,
        resourceType,
        pageSize,
      });
      setPaginationIndex(-1);
      reset(values);
    },
    [reset]
  );

  const form = useMemo(
    () => (
      <form
        onSubmit={handleSubmit(onSearch)}
        className="grid gap-10 sticky left-0 top-20"
      >
        <AutocompleteController
          placeholder="Select the user"
          name="issuerId"
          showError
          label="Activities by"
          options={issuerOptions}
          control={control}
          loading={false}
        />
        <FormSelectController
          label="Resource Type"
          control={control}
          name="resourceType"
        >
          <MenuItem key={AuditResourceType.Fund} value={AuditResourceType.Fund}>
            Deal
          </MenuItem>
          <MenuItem key={AuditResourceType.User} value={AuditResourceType.User}>
            User
          </MenuItem>
          <MenuItem
            key={AuditResourceType.Media}
            value={AuditResourceType.Media}
          >
            Podcast
          </MenuItem>
        </FormSelectController>
        {resourceTypeValue === AuditResourceType.Fund && (
          <AutocompleteController
            showError
            placeholder="Filter deal"
            name="fundId"
            label="Resource"
            options={dealOptions}
            control={control}
            loading={isEmpty(dealOptions)}
          />
        )}
        {resourceTypeValue === AuditResourceType.User && (
          <AutocompleteController
            showError
            placeholder="Filter user"
            name="userId"
            label="Resource"
            options={issuerOptions}
            control={control}
            loading={false}
          />
        )}
        {resourceTypeValue === AuditResourceType.Media && (
          <AutocompleteController
            showError
            placeholder="Filter podcast"
            name="mediaId"
            label="Resource"
            options={mediaOptions}
            control={control}
            loading={false}
          />
        )}
        <FormSelectController
          label="Page size"
          control={control}
          name="pageSize"
        >
          {PAGE_SIZE_OPTIONS.map((value) => (
            <MenuItem key={value} value={value}>
              {value} items
            </MenuItem>
          ))}
        </FormSelectController>
        <div className="col-span-1 md:col-span-1 flex justify-center">
          <Button
            size="small"
            fullWidth
            type={formState.isDirty ? "submit" : undefined}
            variant="contained"
          >
            Search
          </Button>
        </div>
      </form>
    ),
    [
      control,
      dealOptions,
      formState.isDirty,
      handleSubmit,
      issuerOptions,
      mediaOptions,
      onSearch,
      resourceTypeValue,
    ]
  );

  const filterCount = useMemo(() => {
    const { fundId, userId, issuerId } = paginationFilter;

    return [fundId, userId, issuerId].filter(Boolean).length;
  }, [paginationFilter]);

  return (
    <div className="w-full">
      <div className="m9-container">
        <PageTitle className="hidden md:block">Audit Activities</PageTitle>

        <div className="grid grid-cols-1 md:grid-cols-12 md:gap-8">
          <div className="flex flex-col md:hidden">
            <div className="flex justify-between items-center px-3 py-4">
              <Typography variant="button1" className="text-gray-400">
                Filters ({filterCount})
              </Typography>
              <IconButton
                onClick={() => setCollapseFormOpen(!isCollapseFormOpen)}
              >
                {isCollapseFormOpen ? <Close /> : <FilterList />}
              </IconButton>
            </div>
            <Collapse in={isCollapseFormOpen}>
              <div className="mb-4 px-3">{form}</div>
            </Collapse>
          </div>
          <div className="hidden md:flex flex-col justify-between md:col-span-3 h-full">
            {form}
          </div>

          <div className="md:col-span-9">
            {isEmptyResult && (
              <EmptyContentScreen
                title="No logs found"
                icon={<PlaylistRemove />}
                subtitle={
                  <div className="text-center">
                    There are no results for the given search parameters
                  </div>
                }
              />
            )}
            {isLogsLoading && <Loading full />}
            {data && !isEmptyResult && !isLogsLoading && (
              <>
                <div className="space-y-4">
                  {data.items.map((item: any) => (
                    <div key={item.id}>
                      <AuditActivityCard audit={item} />
                    </div>
                  ))}
                </div>
                <div className="flex space-x-2 w-full justify-end mt-4">
                  <Tooltip
                    title="Previous Page"
                    placement="left"
                    disableFocusListener
                    disableTouchListener
                  >
                    <IconButton
                      onClick={() => setPaginationIndex(paginationIndex - 1)}
                      disabled={
                        !paginationTokens[paginationIndex - 1] &&
                        paginationIndex - 1 !== -1
                      }
                      className="border-2 border-gray-200 border-solid disabled:bg-gray-200"
                    >
                      <ChevronLeft />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Next Page"
                    placement="right"
                    disableFocusListener
                    disableTouchListener
                  >
                    <IconButton
                      onClick={() => setPaginationIndex(paginationIndex + 1)}
                      disabled={
                        !isString(paginationTokens[paginationIndex + 1])
                      }
                      className="border-2 border-gray-200 border-solid disabled:bg-gray-200"
                    >
                      <ChevronRight />
                    </IconButton>
                  </Tooltip>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditActivities;
