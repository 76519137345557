import projectReportService from "services/projectReport.service";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { IProjectReport } from "types/project.service";

export const getProjectReportsSWRKey = (projectId: string) =>
  `/Project/${projectId}/report-builder/reports`;

export const useProjectReports = (
  projectId: string | undefined,
  config?: SWRConfiguration<IProjectReport[]>
): SWRResponse<IProjectReport[]> => {
  return useSWR(
    projectId && getProjectReportsSWRKey(projectId),
    projectId ? () => projectReportService.getReports(projectId) : null,
    config
  );
};
