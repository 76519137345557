import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import * as path from "ui/Router/paths";

const customizeIntercom = () => {
  const intercomContainer = document.getElementById("intercom-container");

  if (intercomContainer) {
    const intercomFrame = intercomContainer.querySelector(
      'iframe[name^="intercom-messenger"]'
    ) as HTMLIFrameElement | null;

    const checkIframeContent = () => {
      if (!intercomFrame || !intercomFrame.contentWindow) {
        return false;
      }

      try {
        const iframeDocument =
          intercomFrame.contentDocument || intercomFrame.contentWindow.document;

        if (iframeDocument) {
          const container = iframeDocument.getElementById("intercom-container");

          if (container) {
            const closeBtn = container.querySelector(
              '[aria-label="Close"]'
            ) as HTMLElement;

            if (closeBtn) {
              closeBtn.style.display = "block";
              const icon = closeBtn.querySelector("i");
              if (icon) {
                icon.style.display = "flex";
                icon.style.alignItems = "center";
                icon.style.justifyContent = "center";
                icon.style.height = "100%";
                return true;
              }
            }
          }
        }
      } catch (error) {
        console.error("Error customizing Intercom:", error);
      }
      return false;
    };

    const iframeObserver = new MutationObserver(checkIframeContent);

    if (
      intercomFrame &&
      intercomFrame.contentWindow &&
      intercomFrame.contentWindow.document
    ) {
      iframeObserver.observe(intercomFrame.contentWindow.document, {
        childList: true,
        subtree: true,
      });
    }

    if (intercomFrame) {
      checkIframeContent();
    }
  }
};

export const IntercomCustomizer = () => {
  const { pathname } = useLocation();
  const observerRef = useRef<MutationObserver | null>(null);

  useEffect(() => {
    if (pathname === `/${path.projectList}`) {
      const checkForIntercomContainer = () => {
        const intercomContainer = document.getElementById("intercom-container");
        if (intercomContainer) {
          observerRef.current = new MutationObserver(customizeIntercom);
          observerRef.current.observe(intercomContainer, {
            childList: true,
            subtree: true,
          });
          customizeIntercom();
          return true;
        }
        return false;
      };

      if (!checkForIntercomContainer()) {
        const bodyObserver = new MutationObserver(() => {
          if (checkForIntercomContainer()) {
            bodyObserver.disconnect();
          }
        });

        bodyObserver.observe(document.body, {
          childList: true,
          subtree: true,
        });

        return () => {
          bodyObserver.disconnect();
        };
      }
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = null;
      }
    };
  }, [pathname]);

  return null;
};
