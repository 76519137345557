import { useSnackbar } from "notistack";
import { FC } from "react";
import DealService from "services/deal.service";
import { Modal } from "ui/components";
import { UserAutocompleteForm } from "ui/components/UserAutocompleteForm/UserAutocompleteForm";
import { useDealId, useErrorHandler } from "ui/hooks";
import { useLookupDealFundManagers } from "ui/hooks/Deal/useLookupDealFundManagers";

interface MessageModalProps {
  isOpen: boolean;
  onSubmitHandler?: () => void;
  onClose: () => void;
  exactDealId?: string;
}

export const DealAssignOwnerModal: FC<MessageModalProps> = ({
  isOpen,
  onSubmitHandler,
  onClose,
  exactDealId,
}) => {
  const dealId = useDealId();
  const { data: users, loading } = useLookupDealFundManagers();
  const { handleError } = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (userId: string) => {
    try {
      await DealService.assignDealOwner(exactDealId || dealId, {
        ownerId: userId,
      });

      enqueueSnackbar("Deal successfully assigned", {
        title: "Deal assigned!",
        variant: "success",
      });

      if (onSubmitHandler) {
        onSubmitHandler();
      }
      onClose();
    } catch (e) {
      handleError(e, "It was not possible to assign the deal");
    }
  };

  return (
    <Modal
      title="Select a new deal owner"
      open={isOpen}
      onClose={onClose}
      bodyProps={{ className: "xs:w-[340px] md:w-[488px]" }}
    >
      <UserAutocompleteForm
        users={users}
        isLoading={loading}
        onSubmit={handleSubmit}
        onCancel={onClose}
        submitButtonText="Set new owner"
      />
    </Modal>
  );
};
