import { Tooltip, Typography } from "@mui/material";
import { clsxm } from "application/utils";
import RolesField from "ui/pages/DataRoom/DataRoomFilePermissions/components/RolesField/RolesField";
import { UsersWithRoles } from "ui/pages/DealDataRoom/DealDataRoom.types";
import ProjectPeopleWithAccessAction from "./ProjectSharingPeopleWithAccessAction";
import { filterRolesByImportance } from "redux/Api/DataroomPermissionsApi.utils";

interface ProjectSharingPeopleWithAccessProps {
  className?: string;
  data?: UsersWithRoles;
  projectId?: string;
}

const ProjectSharingPeopleWithAccess = ({
  className,
  data,
  projectId,
}: ProjectSharingPeopleWithAccessProps) => {
  return (
    <div className={clsxm(className)}>
      <Typography className="text-gray-300 text-xs" variant="hairline2">
        People with access
      </Typography>
      <div className="mt-1 flex flex-col gap-2 bg-gray-100 py-2 px-3 max-h-44 overflow-scroll hidden-scrollbar">
        {data?.map((user) => {
          const { name, roles, userId, email } = user;
          return (
            <div
              key={userId}
              className="flex justify-between items-center py-2"
            >
              <div className="grid grid-cols-[50%_50%] w-full">
                <div className="max-w-[250px]">
                  <Tooltip title={name}>
                    <Typography
                      variant="h6"
                      className="font-sans text-dark-text font-bold text-sm overflow-hidden text-ellipsis whitespace-nowrap"
                    >
                      {name}
                    </Typography>
                  </Tooltip>
                  <Tooltip title={email}>
                    <Typography
                      variant="body2"
                      className="text-gray-400 font-sans text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                    >
                      {email}
                    </Typography>
                  </Tooltip>
                </div>
                <div className="flex-nowrap overflow-scroll hidden-scrollbar pb-2 mr-2 whitespace-nowrap">
                  <RolesField
                    roles={filterRolesByImportance(roles)}
                    id={userId}
                    currRoleId={null}
                  />
                </div>
              </div>
              <ProjectPeopleWithAccessAction
                data={user}
                projectId={projectId}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProjectSharingPeopleWithAccess;
