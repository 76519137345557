import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";

export enum SidebarTypeKey {
  chatSidebar = "chatSidebar",
  createReportModule = "createReportModule",
  editReportModule = "editReportModule",
  createOrUpdateTemplate = "createOrUpdateTemplate",
}

export type SidebarState = {
  [K in SidebarTypeKey]: boolean;
};

export const chatSidebarSelector = (state: RootState) => state.chatSidebar;

export const selectIsOpenChatSidebar = createSelector(
  chatSidebarSelector,
  (state) => state.isOpen
);

export const projectReportsSelector = (state: RootState) =>
  state.projectReports;

export const selectIsOpenCreateOrEditTemplate = createSelector(
  projectReportsSelector,
  (state) => state.createOrEditTemplateModalParams.isOpen
);

export const selectIsOpenCreateReportModuleModalParams = createSelector(
  projectReportsSelector,
  (state) => state.createReportModuleModalParams.isOpen
);

export const selectIsOpenEditReportModule = createSelector(
  projectReportsSelector,
  (state) => state.editReportModuleModalParams.isOpen
);

export const sidebarSelector = createSelector(
  [
    selectIsOpenChatSidebar,
    selectIsOpenCreateReportModuleModalParams,
    selectIsOpenEditReportModule,
    selectIsOpenCreateOrEditTemplate,
  ],
  (
    chatSidebar,
    createReportModule,
    editReportModule,
    createOrUpdateTemplate
  ): SidebarState => {
    return {
      chatSidebar,
      createReportModule,
      editReportModule,
      createOrUpdateTemplate,
    };
  }
);
