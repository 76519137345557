import { vestResolver } from "@hookform/resolvers/vest";
import { AuditResourceType } from "domain/audit";
import { isEmpty } from "lodash";
import { create, test, enforce, skipWhen, optional } from "vest";
import { FormFields } from "./AuditActivities";

const schema = create((data: FormFields) => {
  const isMinimumFilled = [
    data.fundId,
    data.userId,
    data.issuerId,
    data.mediaId,
  ].some((value) => !isEmpty(value));

  optional({
    issuerId: () => isMinimumFilled,
    userId: () => isMinimumFilled,
    fundId: () => isMinimumFilled,
  } as Record<keyof FormFields, () => boolean>);

  test("issuerId", "Issuer or Resource must be defined", () => {
    test("issuerId", "Issuer or Resource must be defined", () => {
      enforce(isMinimumFilled).isTruthy();
    });
  });

  skipWhen(
    () => data.resourceType !== AuditResourceType.Fund,
    () => {
      test("fundId", "Issuer or Resource must be defined", () => {
        enforce(isMinimumFilled).isTruthy();
      });
    }
  );

  skipWhen(
    () => data.resourceType !== AuditResourceType.User,
    () => {
      test("userId", "Issuer or Resource must be defined", () => {
        enforce(isMinimumFilled).isTruthy();
      });
    }
  );

  skipWhen(
    () => data.resourceType !== AuditResourceType.Media,
    () => {
      test("mediaId", "Issuer or Resource must be defined", () => {
        enforce(isMinimumFilled).isTruthy();
      });
    }
  );

  // skipWhen(
  //   () => data.resourceType !== AuditResourceType.Organization,
  //   () => {
  //     test("issuerId", "Issuer or Resource must be defined", () => {
  //       enforce(data.organizationId).isNotEmpty();
  //     });
  //   }
  // );
});

export default vestResolver(schema);
