import { isBullpen } from "application/platformConfiguration";

export const toggleItems =
  (condition: boolean, exclude = true) =>
  <T>(
    list: T[],
    key?: keyof T,
    fn = (item: T) => {
      const res = key
        ? list.some((listItem) => listItem[key] === item[key])
        : list.includes(item);
      return exclude ? !res : res;
    }
  ) =>
  (item: T) =>
    condition ? fn(item) : true;

export const excludeItemsOnBullpen = toggleItems(isBullpen());
export const excludeItemsOnBullpenForNotDevs = (isDeveloper: boolean) =>
  toggleItems(isBullpen() && !isDeveloper);
