import { FeedbackError, TimeoutError } from "application/errors";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { defaultsDeep } from "lodash";
import * as Sentry from "@sentry/browser";

interface SummarizedError {
  responseStatusCode?: number;
  responseContent?: string | GenericResponse<unknown> | any;
  genericErrorMessage?: string;
  responseAPICode?: number;
  responseAPIMessage?: string;
}

type ErrorArg = Error | AxiosError | FeedbackError | unknown;

export const useErrorHandler = () => {
  const { enqueueSnackbar } = useSnackbar();

  const parseBlobError = async (error: ErrorArg): Promise<ErrorArg> => {
    const typedError = error as any;

    if (typedError?.response?.data instanceof Blob) {
      const errorData = JSON.parse(await typedError?.response?.data?.text());

      return defaultsDeep(typedError, { response: { data: errorData } });
    }

    return typedError;
  };

  const enqueueUniqueSnackbar = useCallback(
    (title: string, message: string) => {
      enqueueSnackbar(message, {
        title,
        variant: "error",
        preventDuplicate: true,
      });
    },
    [enqueueSnackbar]
  );

  const getSummarizedError = useCallback(
    (error: ErrorArg) =>
      ({
        genericErrorMessage: (error as Error)?.message,
        responseAPIMessage: ((error as AxiosError)?.response?.data as any)?.err
          ?.msg,
        responseAPICode: ((error as AxiosError)?.response?.data as any)?.err
          ?.code,
        responseContent: (error as AxiosError)?.response?.data as any,
        responseStatusCode: (error as AxiosError)?.response?.status,
      } as SummarizedError),
    []
  );

  const handleError = useCallback(
    (
      error: ErrorArg,
      defaultMessage = "An unexpected error has happened",
      customTitle?: string
    ) => {
      const { responseAPIMessage, responseStatusCode, genericErrorMessage } =
        getSummarizedError(error);

      Sentry.captureException(error);

      if (responseAPIMessage) {
        enqueueUniqueSnackbar(
          customTitle || "Something went wrong",
          responseAPIMessage
        );

        return;
      }

      if (responseStatusCode === 403) {
        enqueueUniqueSnackbar(
          "No Access",
          "You don't have access to the current resource."
        );
        return;
      }

      if (responseStatusCode === 500) {
        enqueueUniqueSnackbar(
          "Server error",
          "We had an internal server error. Please try again later."
        );
        return;
      }

      if (error instanceof FeedbackError && genericErrorMessage) {
        enqueueUniqueSnackbar(
          customTitle || "Something went wrong",
          genericErrorMessage
        );

        return;
      }

      if (error instanceof TimeoutError) {
        enqueueUniqueSnackbar(
          customTitle || "The request took too long.",
          "The current request took too long to respond. Please try again later."
        );

        return;
      }

      enqueueUniqueSnackbar(
        customTitle || "Something went wrong",
        defaultMessage
      );

      return;
    },
    [enqueueUniqueSnackbar, getSummarizedError]
  );

  return {
    getSummarizedError,
    handleError,
    parseBlobError,
  };
};
