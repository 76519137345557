import { Avatar, Chip } from "@mui/material";
import { getNameCombined, getNameInitials } from "application/utils";
import { AuditEventType, AuditResourceType } from "domain/audit";
import { FormatType } from "domain/common";
import { isNil, startCase } from "lodash";
import { FC, useMemo } from "react";
import { generatePath, Link } from "react-router-dom";
import { UserProfile } from "types";
import { IAudit } from "types/audit.service";
import { DealGeneralData } from "types/deal";
import { IMedia } from "types/media.service";
import * as paths from "ui/Router/paths";

type AuditValueFormatter = FormatType | ((value: any) => any);

interface AuditSummary {
  actionDescription: string | React.ReactElement | React.ReactElement[];
  formatters?: Record<string, AuditValueFormatter | undefined>;
}

const UserLinkName: FC<{ profile?: UserProfile }> = ({ profile }) => {
  if (!profile) return <div>Name not found</div>;

  const profileLink =
    "/" +
    generatePath(paths.userProfile, {
      id: profile?.customUsername,
    });
  const name =
    getNameCombined(profile?.firstName, profile?.lastName) || "Name not found";

  return (
    <Link to={profileLink} target="_blank" rel="noopener noreferrer">
      <Chip
        component={"span"}
        avatar={
          <Avatar src={profile?.imageUrl}>{getNameInitials(name)}</Avatar>
        }
        className="my-1 mr-1 cursor-pointer hover:underline text-gray-500"
        label={`User: ${name}`}
        variant="outlined"
      />
    </Link>
  );
};

const MediaLinkName: FC<{ media?: IMedia }> = ({ media }) => {
  const isPodcastDeleted = !media;

  const profileLink = !isPodcastDeleted
    ? "/" +
      generatePath(paths.podcastDetail, {
        mediaId: media?.id,
      })
    : null;

  const title =
    media?.privateInfo?.title || media?.publicInfo?.title || "Deleted podcast";

  return (
    <Link
      to={profileLink || "#"}
      onClick={(e) => {
        if (isPodcastDeleted) e.preventDefault();
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Chip
        component={"span"}
        avatar={
          <Avatar src={media?.thumbnailImageUrl}>
            {getNameInitials(title)}
          </Avatar>
        }
        className="my-1 mr-1 cursor-pointer hover:underline text-gray-500"
        label={`Podcast: ${title}`}
        variant="outlined"
      />
    </Link>
  );
};

const DealLinkName: FC<{ deal?: DealGeneralData }> = ({ deal }) => {
  if (!deal) return <div>Name not found</div>;

  const profileLink =
    "/" +
    generatePath(paths.dealRedirectById, {
      id: deal.id,
    });
  const name = deal.name || "Name not found";

  return (
    <Link to={profileLink} target="_blank" rel="noopener noreferrer">
      <Chip
        component={"span"}
        avatar={<Avatar src={deal?.imageUrl}>{getNameInitials(name)}</Avatar>}
        className="my-1 mr-1 cursor-pointer hover:underline text-gray-500"
        label={`Deal: ${name}`}
        variant="outlined"
      />
    </Link>
  );
};

export const useAuditParser = (audit: IAudit) => {
  const {
    relatedUser,
    // metadata,
    issuer,
    newResourceValue,
    oldResourceValue,
    eventType,
  } = audit;

  const isOldResourceValueNil = isNil(oldResourceValue);
  const isNewResourceValueNil = isNil(newResourceValue);

  const { actionDescription, formatters } = useMemo<AuditSummary>(() => {
    switch (eventType) {
      case AuditEventType.AdminUpdateUserProfile:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>profile</b>
            </span>
          ),
        };
      case AuditEventType.AdminUpdateUserRegistrationSteps:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>registration steps</b>
            </span>
          ),
        };
      case AuditEventType.AdminUpdateUserInvestorQualifications:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>investor qualifications</b>
            </span>
          ),
        };
      case AuditEventType.AdminUpdateUserGroups:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>groups</b>
            </span>
          ),
        };
      case AuditEventType.AdminAddUserToGroup:
        return {
          actionDescription: (
            <span>
              <b>added</b> the user to a new <b>group</b>
            </span>
          ),
        };
      case AuditEventType.AdminRemoveUserFromGroup:
        return {
          actionDescription: (
            <span>
              <b>removed</b> the user from a <b>group</b>
            </span>
          ),
        };
      case AuditEventType.FundQuestionUpdate:
        if (isOldResourceValueNil && !isNewResourceValueNil)
          return {
            actionDescription: (
              <span>
                <b>added</b> a new <b>question</b>
              </span>
            ),
            formatters: { Text: FormatType.Markdown },
          };
        if (!isOldResourceValueNil && isNewResourceValueNil)
          return {
            actionDescription: (
              <span>
                <b>deleted</b> a <b>question</b>
              </span>
            ),
            formatters: { Text: FormatType.Markdown },
          };

        return {
          actionDescription: (
            <span>
              <b>updated</b> a <b>question</b>
            </span>
          ),
          formatters: { Text: FormatType.Markdown },
        };
      case AuditEventType.UserEdit:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>user data</b>
            </span>
          ),
        };
      case AuditEventType.UserProfileImageUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>image</b>
            </span>
          ),
        };
      case AuditEventType.UserProfileEdit:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>profile</b>
            </span>
          ),
        };
      case AuditEventType.UserOnboardingUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>onboarding data</b>
            </span>
          ),
        };
      case AuditEventType.UserPreferencesNotificationsUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>notifications preferences</b>
            </span>
          ),
        };
      case AuditEventType.UserPreferencesInterestsUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>interests preferences</b>
            </span>
          ),
        };
      case AuditEventType.UserPreferencesAccountUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>account preferences</b>
            </span>
          ),
        };
      case AuditEventType.UserPreferencesPrivacyUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>privacy preferences</b>
            </span>
          ),
        };
      case AuditEventType.FundDataRoomFileAdd:
        return {
          actionDescription: (
            <span>
              <b>added</b> the a <b>file</b> on <i>data room</i>
            </span>
          ),
        };
      case AuditEventType.FundDataRoomFileRemove:
        return {
          actionDescription: (
            <span>
              <b>removed</b> a <b>file</b> on <i>data room</i>
            </span>
          ),
        };
      case AuditEventType.FundDataRoomFileMove:
        return {
          actionDescription: (
            <span>
              <b>moved</b> a <b>file</b> on <i>data room</i>
            </span>
          ),
        };
      case AuditEventType.FundDataRoomFileRename:
        return {
          actionDescription: (
            <span>
              <b>renamed</b> a <b>file</b> on <i>data room</i>
            </span>
          ),
        };
      case AuditEventType.FundDataRoomFolderRename:
        return {
          actionDescription: (
            <span>
              <b>renamed</b> a <b>folder</b> on <i>data room</i>
            </span>
          ),
        };
      case AuditEventType.FundDataRoomFolderRemove:
        return {
          actionDescription: (
            <span>
              <b>removed</b> a <b>folder</b> on <i>data room</i>
            </span>
          ),
        };
      case AuditEventType.FundDataRoomFolderAdd:
        return {
          actionDescription: (
            <span>
              <b>added</b> a <b>folder</b> on <i>data room</i>
            </span>
          ),
        };
      case AuditEventType.FundDataRoomFolderMove:
        return {
          actionDescription: (
            <span>
              <b>moved</b> a <b>folder</b> on <i>data room</i>
            </span>
          ),
        };
      case AuditEventType.FundCreate:
        return {
          actionDescription: (
            <span>
              <b>created</b> a new <b>fund</b>
            </span>
          ),
        };
      case AuditEventType.FundEdit:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>details</b>
            </span>
          ),
        };
      case AuditEventType.FundCompletedStepsUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>completed steps</b>
            </span>
          ),
        };
      case AuditEventType.FundUpdateImage:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>image</b>
            </span>
          ),
        };
      case AuditEventType.FundTransferOwnership:
        return {
          actionDescription: (
            <span>
              <b>transferred</b> the <b>ownership</b>
            </span>
          ),
        };
      case AuditEventType.FundPolicyUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>policies</b>
            </span>
          ),
        };
      case AuditEventType.FundPublishedStatusUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>published status</b>
            </span>
          ),
        };
      case AuditEventType.FundQuestionAndAnswersEnabledUpdate:
        if (newResourceValue?.isQuestionAndAnswersEnabled)
          return {
            actionDescription: (
              <span>
                <b>enabled</b> the <b>Q & A</b>
              </span>
            ),
          };

        return {
          actionDescription: (
            <span>
              <b>disabled</b> disabled the <b>Q & A</b>
            </span>
          ),
        };
      case AuditEventType.FundParticipationUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>participation request</b>
              {relatedUser && (
                <>
                  {" "}
                  from <UserLinkName profile={relatedUser} />
                </>
              )}
            </span>
          ),
        };
      case AuditEventType.FundSponsorUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>sponsor</b>
            </span>
          ),
        };
      case AuditEventType.FundSponsorDelete:
        return {
          actionDescription: (
            <span>
              <b>deleted</b> the <b>sponsor</b>
            </span>
          ),
        };
      case AuditEventType.FundAdvisorUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>advisor</b>
            </span>
          ),
        };
      case AuditEventType.FundAdvisorDelete:
        return {
          actionDescription: (
            <span>
              <b>deleted</b> the <b>advisor</b>
            </span>
          ),
        };
      case AuditEventType.FundLeadInvestorUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>lead investor</b>
            </span>
          ),
        };
      case AuditEventType.FundLeadInvestorDelete:
        return {
          actionDescription: (
            <span>
              <b>deleted</b> the <b>lead investor</b>
            </span>
          ),
        };
      case AuditEventType.FundKeyBiographerUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>key biographer</b>
            </span>
          ),
        };
      case AuditEventType.FundSummaryUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>summary</b>
            </span>
          ),
        };
      case AuditEventType.FundKeyHighlightsUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>key highlights</b>
            </span>
          ),
        };
      case AuditEventType.FundHistoricalPerformanceUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>historical performance</b>
            </span>
          ),
        };
      case AuditEventType.InviteRelationshipAdd:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>invite relationship</b>
            </span>
          ),
        };
      case AuditEventType.InviteRemove:
        return {
          actionDescription: (
            <span>
              <b>removed</b> the <b>invite</b>
            </span>
          ),
        };
      case AuditEventType.InviteSend:
        return {
          actionDescription: (
            <span>
              <b>sent</b> the <b>invite</b>
            </span>
          ),
        };
      case AuditEventType.MediaRemove:
        return {
          actionDescription: (
            <span>
              triggered the <b>remove</b> action
            </span>
          ),
        };
      case AuditEventType.MediaUpdate:
        return {
          actionDescription: (
            <span>
              <b>updated</b> the <b>details</b>
            </span>
          ),
        };
      default:
        return {
          actionDescription: `triggered the action ${startCase(eventType)}`,
          formatters: {},
        };
    }
  }, [
    eventType,
    isNewResourceValueNil,
    isOldResourceValueNil,
    newResourceValue?.isQuestionAndAnswersEnabled,
    relatedUser,
  ]);

  const targetResourceContent = useMemo(() => {
    switch (audit.resourceType) {
      case AuditResourceType.Fund:
        return <DealLinkName deal={audit.mainResource as DealGeneralData} />;
      case AuditResourceType.Organization:
        return null;
      case AuditResourceType.User:
        return <UserLinkName profile={audit.mainResource as UserProfile} />;
      case AuditResourceType.Media:
        return <MediaLinkName media={audit.mainResource as IMedia} />;
    }
  }, [audit.mainResource, audit.resourceType]);

  return {
    title: (
      <span className="align-middle">
        <UserLinkName profile={issuer} /> {actionDescription} for the{" "}
        {targetResourceContent}
      </span>
    ),
    formatters: {
      publicDescription: FormatType.Markdown,
      longDescription: FormatType.Markdown,
      imageUrl: (url: any) =>
        !url ? "Empty" : <img src={url} alt={url} className="max-h-96" />,
      availablePart: FormatType.Money,
      fundSize: FormatType.Money,
      ...formatters,
    } as Record<string, AuditValueFormatter>,
  };
};
