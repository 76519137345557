import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIntercomUserHash } from "redux/Auth/actions";
import userService from "services/user.service";
import { useTypedSelector } from "ui/hooks";
import * as path from "ui/Router/paths";
import { IntercomCustomizer } from "./IntercomCustomizer";
import { useFeatureFlag } from "ui/hooks/useFeatureFlag";
import { FeatureFlagId } from "domain/featureFlag";

export const IntercomUpdater = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { authorized, user, intercomUserHash } = useTypedSelector(
    (state) => state.auth
  );
  const { isFlagEnabled } = useFeatureFlag();
  const isIntercomEnabled = isFlagEnabled(FeatureFlagId.IntercomChat);

  const prevAuthorizedRef = useRef(authorized);

  useEffect(() => {
    if (!isIntercomEnabled) {
      if (intercomUserHash) {
        dispatch(setIntercomUserHash(""));
      }
      return;
    }
  }, [dispatch, intercomUserHash, isIntercomEnabled]);

  useEffect(() => {
    if (!isIntercomEnabled) return;
    if (!authorized) return;
    if (intercomUserHash) return;

    const fetchIntercomUserHash = async () => {
      const {
        response: {
          userHash: { hash },
        },
      } = await userService.getIntercomUserHash();
      dispatch(setIntercomUserHash(hash));
    };

    fetchIntercomUserHash();
  }, [authorized, intercomUserHash, dispatch, isIntercomEnabled]);

  useEffect(() => {
    if (!isIntercomEnabled) return;
    if (authorized !== prevAuthorizedRef.current) {
      if (!authorized && typeof window.Intercom === "function") {
        window.Intercom("shutdown");
      }
    }
  }, [authorized, user, isIntercomEnabled]);

  useEffect(() => {
    if (!isIntercomEnabled) return;
    if (authorized && typeof window.Intercom === "function") {
      window.Intercom("update", {
        hide_default_launcher: pathname !== `/${path.projectList}`,
      });
    }
  }, [pathname, authorized, isIntercomEnabled]);

  if (!isIntercomEnabled) {
    return null;
  }

  return <IntercomCustomizer />;
};
