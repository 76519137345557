import { IAudit, IGetAuditFilter } from "types/audit.service";
import { apiClient } from "./clients";

class AuditService {
  private readonly basePath = "/Audit";

  async getAudit(
    filters: IGetAuditFilter
  ): Promise<PaginatedTokenResponse<IAudit>> {
    const { data } = await apiClient.get(this.basePath, {
      params: filters,
    });

    return data?.response;
  }
}

export default new AuditService();
