import {
  CognitoUser,
  CognitoUserWithoutStorage,
  ObjectWithoutStorage,
} from "./types";

export const removeStorageFromObject = <T extends { storage?: any }>(
  obj: T
): ObjectWithoutStorage<T> => {
  const { storage: _, ...rest } = obj;
  return rest;
};

export const removeStorageFromUser = (
  user: CognitoUser
): CognitoUserWithoutStorage => ({
  ...removeStorageFromObject(user),
  pool: {
    ...removeStorageFromObject(user.pool),
  },
});
