import { lazy } from "react";
import { Route } from "react-router-dom";
import { SyndicationManagementPartnerCreateWrapper } from "ui/pages/SyndicationManagement";

const SyndicationManagementPartnerDetailsCreate = lazy(
  () =>
    import(
      "ui/pages/SyndicationManagement/SyndicationManagementPartnerDetailsCreate"
    )
);

const SyndicationManagementPartnerDetailsUpdate = lazy(
  () =>
    import(
      "ui/pages/SyndicationManagement/SyndicationManagementPartnerDetailsCreate/SyndicationManagementPartnerDetailsCreate.update"
    )
);

const SyndicationManagementPartnerLegalUpdate = lazy(
  () =>
    import(
      "ui/pages/SyndicationManagement/SyndicationManagementPartnerLegalUpdate"
    )
);

const SyndicationManagementPartnerBrandingUpdate = lazy(
  () =>
    import(
      "ui/pages/SyndicationManagement/SyndicationManagementPartnerBrandingUpdate"
    )
);

const SyndicationManagementLoginPreview = lazy(
  () =>
    import("ui/pages/SyndicationManagement/SyndicationManagementLoginPreview")
);

export const partnerRoutes = [
  <Route path="partner" key="adminManagementPartner">
    <Route
      path="create"
      element={<SyndicationManagementPartnerCreateWrapper />}
    >
      <Route index element={<SyndicationManagementPartnerDetailsCreate />} />
    </Route>

    <Route path=":partnerId">
      <Route path="preview" element={<SyndicationManagementLoginPreview />} />
      <Route
        path="update"
        element={<SyndicationManagementPartnerCreateWrapper />}
      >
        <Route index element={<SyndicationManagementPartnerDetailsUpdate />} />
        <Route
          path="legal"
          element={<SyndicationManagementPartnerLegalUpdate />}
        />
        <Route
          path="branding"
          element={<SyndicationManagementPartnerBrandingUpdate />}
        />
      </Route>
    </Route>
  </Route>,
];
