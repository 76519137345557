import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/rootReducer";
import { sidebarMatcher } from "./sidebarMatcher";
import { SidebarTypeKey } from "redux/selectors";

export interface UIState {
  showDisclosureModal: boolean;
  showPodcastModal: boolean;
  podcastModalDealId: string | null;
  isOpenProfileDisabled: boolean;
  forceWhiteHeader: boolean;
  isBetaBannerHidden: boolean;
  isContentFullScreen: boolean;
  openSidebarKey: SidebarTypeKey | null;
}

const initialState: UIState = {
  showDisclosureModal: false,
  showPodcastModal: false,
  podcastModalDealId: null,
  isOpenProfileDisabled: false,
  forceWhiteHeader: false,
  isBetaBannerHidden: false,
  isContentFullScreen: false,
  openSidebarKey: null,
};

const UISlice = createSlice({
  name: "UI",
  initialState,
  reducers: {
    showDisclosureModal(state: UIState) {
      state.showDisclosureModal = true;
    },
    hideDisclosureModal(state: UIState) {
      state.showDisclosureModal = false;
    },
    showPodcastModal(
      state: UIState,
      { payload }: PayloadAction<string | null>
    ) {
      state.showPodcastModal = true;
      state.podcastModalDealId = payload;
    },
    hidePodcastModal(state: UIState) {
      state.showPodcastModal = false;
      state.podcastModalDealId = null;
    },
    setIsOpenProfileDisabled(
      state: UIState,
      { payload }: PayloadAction<boolean>
    ) {
      state.isOpenProfileDisabled = payload;
    },
    resetUI(state: UIState) {
      state = initialState;
    },
    setForceWhiteHeader(state: UIState, { payload }: PayloadAction<boolean>) {
      state.forceWhiteHeader = payload;
    },
    setContentFullScreen(state: UIState, { payload }: PayloadAction<boolean>) {
      state.isContentFullScreen = payload;
    },
    hideBetaBanner(state: UIState) {
      state.isBetaBannerHidden = true;
    },
    setOpenSidebarKey(
      state: UIState,
      { payload }: PayloadAction<SidebarTypeKey | null>
    ) {
      state.openSidebarKey = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(...sidebarMatcher);
  },
});

const selectUIState = (state: RootState) => state.UI;

export const selectOpenSidebarKey = createSelector(
  selectUIState,
  (state) => state.openSidebarKey
);

export const {
  showDisclosureModal,
  hideDisclosureModal,
  resetUI,
  hidePodcastModal,
  showPodcastModal,
  setIsOpenProfileDisabled,
  setForceWhiteHeader,
  hideBetaBanner,
  setContentFullScreen,
  setOpenSidebarKey,
} = UISlice.actions;

export default UISlice.reducer;
