import { isBullpen } from "application/platformConfiguration";

export const text = isBullpen()
  ? {
      DATAROOM_TITLE: "Data Sources",
      DATAROOM_PERMISSIONS_CRUMB: "Data sources",
      SYNDICATION_DEFAULT_WELCOME_MESSAGE:
        "Research, analytics, and investment memo drafting seamlessly integrated into a single workspace",
    }
  : {
      DATAROOM_TITLE: "Data Room",
      DATAROOM_PERMISSIONS_CRUMB: "Data room",
      SYNDICATION_DEFAULT_WELCOME_MESSAGE:
        "As a member, you'll have deal access to a range of investment products, including private equity, venture capital, real estate, and more.",
    };
