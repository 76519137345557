import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Logo } from "ui/components";
import { Link } from "react-router-dom";
import * as paths from "ui/Router/paths";
import { useAuthorized, useSyndicationLayout } from "ui/hooks";
import { NewsLetterSubscribe } from "../NewsLetterSubscribe/NewsLetterSubscribe";
import { CommonLinks } from "domain/common";
import { defaultDisclosure } from "domain/policy";
import { getCurrentYear } from "application/utils";
import MarkdownToJSX from "markdown-to-jsx";

export const Footer = () => {
  const { authorized } = useAuthorized();
  const syndicationLayout = useSyndicationLayout();

  const [cookiesAccepted, setCookiesAccepted] = useState(
    Boolean(localStorage.getItem("cookiesAccepted"))
  );

  const handleAcceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setCookiesAccepted(true);
  };

  return (
    <div className="pt-[44px] pb-[76px] bg-[#F6FAFC]">
      <div className="m9-container py-0">
        <div className="py-8 grid grid-cols-1 sm:grid-cols-12 gap-2 md:gap-8">
          <div className="sm:col-span-3">
            <div className="pb-8">
              <Logo />
            </div>
            <Typography
              variant="body11"
              className="text-primary-600 font-semibold"
            >
              Private Equity
              <br />
              Secondary Marketplace
            </Typography>
          </div>
          <div className="sm:col-span-2">
            <Typography
              variant="body2"
              className="font-bold text-primary pb-10"
            >
              Home
            </Typography>
            <div className="space-y-6 flex flex-col">
              <Link to="/invest">
                <Typography
                  variant="button2"
                  className="font-normal text-primary"
                >
                  Invest
                </Typography>
              </Link>
              <Link to="/syndicate">
                <Typography
                  variant="button2"
                  className="font-normal text-primary"
                >
                  Syndicate
                </Typography>
              </Link>
              {/*<Link to="#">*/}
              {/*  <Typography*/}
              {/*    variant="button2"*/}
              {/*    className="font-normal text-primary"*/}
              {/*  >*/}
              {/*    Contact*/}
              {/*  </Typography>*/}
              {/*</Link>*/}
            </div>
          </div>
          <div className="sm:col-span-2">
            <Typography
              variant="body2"
              className="font-bold text-primary pb-10"
            >
              Resources
            </Typography>
            <div className="space-y-6 flex flex-col">
              <a href={CommonLinks.Blog} target="_blank" rel="noreferrer">
                <Typography
                  variant="button2"
                  className="font-normal text-primary"
                >
                  Blog
                </Typography>
              </a>
              <Link to="/podcasts">
                <Typography
                  variant="button2"
                  className="font-normal text-primary"
                >
                  Podcasts
                </Typography>
              </Link>
            </div>
          </div>

          {!authorized && (
            <div className="sm:col-span-2">
              <Typography
                variant="body2"
                className="font-bold text-primary pb-10"
              >
                Start now
              </Typography>
              <div className="space-y-6 flex flex-col">
                <Link to={paths.signUpPath}>
                  <Typography
                    variant="button2"
                    className="font-normal text-primary"
                  >
                    Sign up
                  </Typography>
                </Link>
                <Link to={paths.signInPath}>
                  <Typography
                    variant="button2"
                    className="font-normal text-primary"
                  >
                    Login
                  </Typography>
                </Link>
              </div>
            </div>
          )}

          <div className="sm:col-span-3">
            <Typography
              variant="body2"
              className="font-bold text-primary pb-10"
            >
              Join Newsletter
            </Typography>
            <div className="space-y-6 flex flex-col">
              <Typography variant="caption" className="text-primary">
                Subscribe to our newsletter for updates and new product related
                news
              </Typography>
              <div className="wrapper">
                <NewsLetterSubscribe />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="m9-container !pt-0">
          <Divider />
          <div className="flex justify-between items-center pt-6">
            <Typography variant="caption2" className="text-primary">
              Copyright © {getCurrentYear()}{" "}
              {syndicationLayout.isSyndicate
                ? syndicationLayout.mainInfo.legalName
                : "Emerge9"}
              . All rights reserved
            </Typography>
            {!cookiesAccepted && (
              <Typography variant="caption2" className="text-primary">
                We use cookies for better service.{" "}
                <span
                  className="text-primary font-bold cursor-pointer"
                  onClick={handleAcceptCookies}
                >
                  Accept
                </span>
              </Typography>
            )}
          </div>
        </div>
      </div>
      <div className="m9-container">
        <Typography variant="body2" className="font-bold text-[#828383] mb-4">
          Disclosure
        </Typography>
        <Typography
          component="p"
          variant="caption"
          className="text-[#727272] leading-6"
        >
          {defaultDisclosure()}
        </Typography>

        {syndicationLayout.isSyndicate &&
        syndicationLayout?.legalInfo?.disclosure &&
        syndicationLayout?.legalInfo?.addToFooter ? (
          <div className="mt-5">
            <MarkdownToJSX>
              {syndicationLayout.legalInfo.disclosure}
            </MarkdownToJSX>
          </div>
        ) : (
          ""
        )}
        {/*<br />*/}
        {/*<Typography component="p" variant="caption" className="text-gray-200">*/}
        {/*  Broker-dealer services are provided by Finalis Securities LLC, a*/}
        {/*  FINRA/SIPC member. For additional information, please see here.*/}
        {/*  Emerge9 and Finalis Securities LLC are separate, unaffiliated entities*/}
        {/*</Typography>*/}
      </div>
    </div>
  );
};

export default Footer;
