import { FeatureFlagId } from "domain/featureFlag";
import { chain, isUndefined } from "lodash";
import { useCallback, useMemo } from "react";
import featureFlagService from "services/featureFlag.service";
import useSWR, { useSWRConfig } from "swr";
import { IGetFeatureFlag } from "types/featureFlagService";

export const useFeatureFlag = (partnerId?: string) => {
  const { cache } = useSWRConfig();
  const { data, error, mutate } = useSWR(
    ["all-feature-flags", partnerId],
    async (params) => {
      const [key] = params;

      const flagData = await featureFlagService.getAllFlags(partnerId);
      if (flagData.flags) {
        return flagData;
      }
      return cache.get(key) as IGetFeatureFlag;
    },
    {
      refreshInterval: 60000 * 5,
      revalidateOnFocus: false,
      errorRetryCount: 3,
    }
  );

  const isFlagEnabled = useCallback<(key: FeatureFlagId) => boolean>(
    (key: FeatureFlagId) => {
      return Boolean(data?.flags?.[key]?.isEnabled);
    },
    [data?.flags]
  );

  const flagsAsList = useMemo(() => {
    if (!data?.flags) return [];

    return chain(data.flags)
      .keys()
      .map((key) => data.flags[key as FeatureFlagId])
      .sortBy((item) => item.title)
      .value();
  }, [data?.flags]);

  const isLoading = [data, error].every(isUndefined);

  const isProjectsOnly =
    isFlagEnabled(FeatureFlagId.Projects) &&
    !isFlagEnabled(FeatureFlagId.Deals);
  const isDealsOn = isFlagEnabled(FeatureFlagId.Deals);
  const isProjectsOn = isFlagEnabled(FeatureFlagId.Projects);
  const isDealsAndProjects =
    isFlagEnabled(FeatureFlagId.Deals) && isFlagEnabled(FeatureFlagId.Projects);
  const isDealsOnly =
    isFlagEnabled(FeatureFlagId.Deals) &&
    !isFlagEnabled(FeatureFlagId.Projects);

  return {
    error,
    flags: data?.flags,
    isFlagEnabled,
    isLoading,
    flagsAsList,
    mutate,
    isProjectsOnly,
    isDealsOn,
    isProjectsOn,
    isDealsAndProjects,
    isDealsOnly,
  };
};
