export enum AuditResourceType {
  Fund = "FUND",
  Organization = "ORGANIZATION",
  User = "USER",
  Media = "MEDIA",
}

export enum AuditEventType {
  AdminUpdateUserProfile = "admin_updateUserProfile",
  AdminUpdateUserRegistrationSteps = "admin_updateUserRegistrationSteps",
  AdminUpdateUserInvestorQualifications = "admin_updateUserInvestorQualifications",
  AdminUpdateUserGroups = "admin_updateUserGroups",
  AdminAddUserToGroup = "admin_addUserToGroup",
  AdminRemoveUserFromGroup = "admin_removeUserFromGroup",
  FundQuestionUpdate = "fund_question_update",
  UserEdit = "user_edit",
  UserProfileImageUpdate = "user_profile_imageUpdate",
  UserProfileEdit = "user_profile_edit",
  UserOnboardingUpdate = "user_onboarding_update",
  UserPreferencesNotificationsUpdate = "user_preferences_notifications_update",
  UserPreferencesInterestsUpdate = "user_preferences_interests_update",
  UserPreferencesAccountUpdate = "user_preferences_account_update",
  UserPreferencesPrivacyUpdate = "user_preferences_privacy_update",
  FundDataRoomFileAdd = "fund_dataRoom_fileAdd",
  FundDataRoomFileRemove = "fund_dataRoom_fileRemove",
  FundDataRoomFileMove = "fund_dataRoom_fileMove",
  FundDataRoomFileRename = "fund_dataRoom_fileRename",
  FundDataRoomFolderRename = "fund_dataRoom_folderRename",
  FundDataRoomFolderRemove = "fund_dataRoom_folderRemove",
  FundDataRoomFolderAdd = "fund_dataRoom_folderAdd",
  FundDataRoomFolderMove = "fund_dataRoom_folderMove",
  FundCreate = "fund_create",
  FundEdit = "fund_edit",
  FundCompletedStepsUpdate = "fund_completedStepsUpdate",
  FundUpdateImage = "fund_updateImage",
  FundTransferOwnership = "fund_transferOwnership",
  FundPublish = "fund_publish",
  FundPolicyUpdate = "fund_policyUpdate",
  FundPublishedStatusUpdate = "fund_publishedStatusUpdate",
  FundQuestionAndAnswersEnabledUpdate = "fund_questionAndAnswersEnabledUpdate",
  FundParticipationUpdate = "fund_participation_update",
  FundSponsorUpdate = "fund_sponsor_update",
  FundSponsorDelete = "fund_sponsor_delete",
  FundAdvisorUpdate = "fund_advisor_update",
  FundAdvisorDelete = "fund_advisor_delete",
  FundLeadInvestorUpdate = "fund_leadInvestor_update",
  FundLeadInvestorDelete = "fund_leadInvestor_delete",
  FundKeyBiographerUpdate = "fund_keyBiographer_update",
  FundSummaryUpdate = "fund_summary_update",
  FundKeyHighlightsUpdate = "fund_keyHighlights_update",
  FundHistoricalPerformanceUpdate = "fund_historicalPerformance_update",
  InviteSend = "invite_send",
  InviteRemove = "invite_cancel",
  InviteRelationshipAdd = "invite_relationshipAdd",
  MediaUpdate = "media_update",
  MediaRemove = "media_remove",
}
