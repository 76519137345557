import { config } from "config";
import { FC, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Loading } from "ui/components";
import SEOHelmet from "ui/components/SEOHelmet/SEOHelmet";
import { useLocationRedirects, useOnboardingSteps } from "ui/hooks";
import { useTypedSelector } from "ui/hooks/useTypeSelector";
import * as paths from "ui/Router/paths";

interface AuthorizedOutletProps {
  dismissRedirect?: boolean;
}

export const AuthorizedOutlet: FC<AuthorizedOutletProps> = ({
  dismissRedirect,
}) => {
  const { authorized, user, intercomUserHash } = useTypedSelector(
    (state) => state.auth
  );
  const { data: onboardingSteps, isValidating } = useOnboardingSteps();

  const location = useLocation();
  const { isLoading, checkAndRedirectToStep } = useLocationRedirects();

  useEffect(() => {
    if (
      authorized &&
      user?.attributes?.email &&
      user?.attributes?.email_verified &&
      intercomUserHash &&
      typeof window.Intercom === "function"
    ) {
      window.Intercom("boot", {
        app_id: config?.intercomAppId,
        name: `${user?.attributes?.name} ${user?.attributes?.family_name}`,
        email: user?.attributes?.email,
        user_hash: intercomUserHash,
      });
    }
  }, [authorized, user?.attributes, intercomUserHash]);

  useEffect(() => {
    const redirect = async () => {
      if (!dismissRedirect && !isValidating) {
        await checkAndRedirectToStep(onboardingSteps?.response);
      }
    };

    redirect();
  }, [checkAndRedirectToStep, dismissRedirect, onboardingSteps, isValidating]);

  if (!dismissRedirect && isLoading) {
    return <Loading full />;
  }

  return authorized ? (
    <>
      <Outlet />
      <SEOHelmet />
    </>
  ) : (
    <Navigate to={"/" + paths.signInPath} state={{ from: location }} replace />
  );
};
