import projectReportService from "services/projectReport.service";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { IProjectReportPreset } from "types/project.service";

export const getProjectPresetSWRKey = (projectId: string, presetId: string) =>
  `/Project/${projectId}/report-builder/presets/${presetId}`;

export const useProjectPreset = (
  projectId: string,
  presetId: string | undefined,
  config?: SWRConfiguration<IProjectReportPreset>
): SWRResponse<IProjectReportPreset> => {
  return useSWR(
    presetId && getProjectPresetSWRKey(projectId, presetId),
    () => projectReportService.getPreset(projectId, presetId!),
    config
  );
};
