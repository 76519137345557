import { config } from "config";
import { bullpenBrandingColors, defaultBrandingColors } from "domain/common";
import { PlatformProjectName } from "domain/platform";
import * as paths from "ui/Router/paths";

export const isBullpen = () =>
  config.projectName === PlatformProjectName.Bullpen;

export const isEmerge9 = () =>
  config.projectName === PlatformProjectName.Emerge9;

interface IPlatformConfiguration {
  name: PlatformProjectName;
  copyrightName: string;
  displayName: string;
  legalName: string;
  primaryDescription: string;
  secondaryDescription: string;
  landingTitle: string;
  landingDescription: string;
  defaultLoggedHomePagePath: string;
  defaultWelcomePagePath: string;
  supportEmail: string;
  domainName: string;
  brandingColors: IBrandingColors;
  metaConfig: MetaConfig;
}

export interface MetaConfig {
  title: string;
  description: string;
  favicon: string;
  manifest: string;
}

export const platformConfiguration: IPlatformConfiguration = isBullpen()
  ? {
      name: PlatformProjectName.Bullpen,
      displayName: "Bullpen",
      copyrightName: "Emerge9 Inc",
      legalName: "Bullpen Inc.",
      primaryDescription:
        "Bullpen - Unlock your Analysts' potential for more impactful work",
      secondaryDescription:
        "Unlock your Analysts' potential for more impactful work",
      landingTitle: "Financial Analyst AI",
      landingDescription:
        "Unlock your Analysts' potential for more impactful work",
      defaultLoggedHomePagePath: paths.projectList,
      defaultWelcomePagePath: paths.projectList,
      supportEmail: "support@bullpen.ai",
      domainName: "bullpen.ai",
      brandingColors: bullpenBrandingColors,
      metaConfig: {
        title: "Financial Analyst AI",
        description: "Unlock your Analysts' potential for more impactful work",
        favicon: "/bullpen/favicon.ico",
        manifest: "/bullpen/site.webmanifest",
      },
    }
  : {
      name: PlatformProjectName.Emerge9,
      displayName: "Emerge9",
      copyrightName: "Emerge9",
      legalName: "Emerge9 Inc.",
      primaryDescription:
        "Emerge9 - Co-invest alongside the top private equity managers. We provide exclusive access to a diversified set of co-investments with a focus on liquidity and transparency",
      secondaryDescription:
        "Exploring the growing opportunities in GP-led secondary markets with the Emerge9 team and key industry partners and thought leaders.",
      landingTitle: "Private Equity Co-investments Alongside Top Institutions",
      landingDescription:
        "Secondary Private Equity Co-investment Platform for Single and Multi-Asset Transactions, led by experienced General Partners",
      defaultLoggedHomePagePath: paths.dealsAll,
      defaultWelcomePagePath: paths.signUpWelcome,
      supportEmail: "support@emerge9.com",
      domainName: "emerge9.com",
      brandingColors: defaultBrandingColors,
      metaConfig: {
        title: "Private Equity Co-investments Alongside Top Institutions",
        description:
          "Secondary Private Equity Co-investment Platform for Single and Multi-Asset Transactions, led by experienced General Partners",
        favicon: "/emerge9/favicon.ico",
        manifest: "/emerge9/manifest.json",
      },
    };

export const metaConfig = platformConfiguration.metaConfig;
export const brandingColors = platformConfiguration.brandingColors;
