export const config = {
  env: process.env.REACT_APP_ENV!,
  projectName: process.env.REACT_APP_PROJECT_NAME!,
  siteUrl: process.env.REACT_APP_SITE_URL!,
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL!,
  },
  aws: {
    aws_project_region: process.env.REACT_APP_AWS_REGION!,
    aws_cognito_region: process.env.REACT_APP_AWS_REGION!,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID!,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_WEB_CLIENT_ID!,
    oauth: {
      domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN!,
      scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: `${process.env
        .REACT_APP_SITE_URL!}/oauth/federated-signin`,
      redirectSignOut: process.env.REACT_APP_SITE_URL!,
      responseType: "code",
    },
  },
  sentry: { dsn: process.env.REACT_APP_SENTRY_DSN! },
  intercomAppId: process.env.REACT_APP_INTERCOM_APP_ID!,
};

export const isDev = () => config.env === "dev";
export const isStage = () => config.env === "stage";
export const isProd = () => config.env === "prod";
