import { Close } from "@mui/icons-material";
import { Chip, Tooltip } from "@mui/material";
import { Loading } from "ui/components";
import { DataRoomPermissionRole } from "ui/pages/DealDataRoom/DealDataRoom.types";
import { Nullable } from "types";
import { useChipStyles } from "../../hooks/useChipStyles";
import { clsxm } from "application/utils";

interface RolesFieldProps {
  id: string;
  roles: DataRoomPermissionRole[];
  currRoleId: Nullable<string>;
  isLoading?: boolean;
  onDelete?: (id: string, roleId: string) => () => void;
  chipClassName?: string;
  useIsRemovable?: boolean;
}

const RolesField = ({
  id,
  roles,
  isLoading = false,
  currRoleId,
  onDelete,
  chipClassName,
  useIsRemovable = false,
}: RolesFieldProps) => {
  const { getChipsStyles, getDeleteIconStyles } = useChipStyles();

  return (
    <>
      {roles?.map(({ roleId, roleName, isRemovable }) => (
        <Tooltip
          key={roleId}
          title={roleName}
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          }}
        >
          <Chip
            label={roleName}
            onDelete={
              useIsRemovable
                ? isRemovable
                  ? onDelete?.(id, roleId)
                  : undefined
                : onDelete?.(id, roleId)
            }
            deleteIcon={
              currRoleId === `${id}${roleId}` && isLoading ? (
                <Loading size={16} />
              ) : (
                <Close
                  className="w-[16px] h-[16px]"
                  sx={getDeleteIconStyles(roleId)}
                />
              )
            }
            sx={{ ...getChipsStyles(roleId), maxWidth: "120px" }}
            className={clsxm("mr-1", chipClassName)}
          />
        </Tooltip>
      ))}
    </>
  );
};

export default RolesField;
