import { excludeItemsOnBullpen } from "bullpen/utils/excludeItemsWhenBullpen";
import { uniqBy } from "lodash";
import {
  PredefinedRoles,
  UserWithRoles,
} from "ui/pages/DealDataRoom/DealDataRoom.types";
import {
  DataRoomPermissionRole,
  DataRoomRole,
} from "ui/pages/DealDataRoom/DealDataRoom.types";
import { pipe } from "application/utils";
import { Nullable } from "types";

export const removeRolesDuplicates = (
  users?: Nullable<UserWithRoles[]>
): UserWithRoles[] =>
  users?.map(({ roles, ...rest }) => ({
    roles: uniqBy(roles, "roleId"),
    ...rest,
  })) || [];

export const filterRoles = (
  data: DataRoomRole[],
  exclude: string[],
  fn: <T>(list: T[]) => (item: T) => boolean = excludeItemsOnBullpen
) => data.filter(({ roleId }) => fn(exclude)(roleId));

export const transformUsersWithRoles = (
  users?: Nullable<UserWithRoles[]>
): UserWithRoles[] =>
  users?.map(({ roles, ...rest }) => ({
    roles: pipe(
      (roles: DataRoomPermissionRole[]) => uniqBy(roles, "roleId"),
      filterRolesByImportance
    )(roles),
    ...rest,
  })) || [];

export const filterRolesByImportance = (
  roles: DataRoomPermissionRole[],
  hierarchyRoleIds: string[] = [PredefinedRoles.VIEWER, PredefinedRoles.EDITOR]
): DataRoomPermissionRole[] =>
  hierarchyRoleIds.reduceRight(
    (filteredRoles, currentRoleId, index, originalIds) => {
      if (filteredRoles.some(({ roleId }) => roleId === currentRoleId)) {
        const idsToExclude = originalIds.slice(0, index);
        return filteredRoles.filter(
          ({ roleId }) => !idsToExclude.includes(roleId)
        );
      }
      return filteredRoles;
    },
    roles
  );
