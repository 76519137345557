import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { FeedbackError } from "application/errors";
import clsx from "clsx";
import React, { ComponentPropsWithoutRef, FC, useMemo } from "react";
import { useCallback } from "react";
import { FieldError } from "react-hook-form";
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from "react-image-crop";
import { Modal, Loading } from "ui/components";
import { useErrorHandler } from "ui/hooks";
import { ImageIcon } from "ui/icons";
import { ImageUploadHelperTooltip } from "../ImageUpload/ImageUpload";
import { getCroppedImageData } from "./canvasUtils";

export interface ImageUploadWithCropperProps
  extends ComponentPropsWithoutRef<"div"> {
  imagePreviewClassName?: string;
  className?: string;
  imgUrlSrc?: string;
  disabled?: boolean;
  error?: FieldError;
  circularCrop?: boolean;
  caption?: string;
  sizeLimit?: number;
  title: string;
  valueSetter: (value: Blob | null) => void;
  freeform?: boolean;
  loading?: boolean;
  cropAspect?: "rectangle" | undefined;
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export const ImageUploadWithCropper: FC<ImageUploadWithCropperProps> = ({
  className,
  imgUrlSrc = "",
  circularCrop,
  title,
  caption,
  sizeLimit = 3e6,
  valueSetter,
  imagePreviewClassName,
  error,
  freeform,
  disabled,
  loading,
  cropAspect,
}) => {
  const { handleError } = useErrorHandler();
  const [open, setOpen] = React.useState(false);
  const [imgURL, setImgURL] = React.useState("");
  const [imgSrc, setImgSrc] = React.useState("");
  const previewCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const imgRef = React.useRef<HTMLImageElement>(null);
  const [crop, setCrop] = React.useState<Crop>();
  const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>();
  const [scale] = React.useState(1);
  const [cropping, setCropping] = React.useState(false);

  const aspect = useMemo(() => {
    if (cropAspect && cropAspect === "rectangle") {
      return 16 / 9;
    }

    if (freeform) {
      return undefined;
    }

    return 1;
  }, [cropAspect, freeform]);

  const isSelectedArea = [completedCrop?.width, completedCrop?.height].every(
    Boolean
  );

  const cropImage = useCallback(async () => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      const dataURL = await getCroppedImageData(
        imgRef.current,
        previewCanvasRef.current,
        imgSrc,
        completedCrop
      );
      return dataURL;
    }
    return;
  }, [completedCrop, imgSrc]);

  const selectFile = () => {
    fileInputRef.current?.click();
  };

  const onSelectFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const file = e.target?.files?.[0];

        setOpen(true);

        if (!file) {
          throw new FeedbackError("It was not possible to find the file");
        }

        if (file.size > sizeLimit) {
          throw new FeedbackError(
            `The file size exceeds the limit. (Max.: ${(
              sizeLimit / 1e6
            ).toFixed(1)}mb)`
          );
        }

        const reader = new FileReader();
        reader.addEventListener("load", () =>
          setImgSrc((reader.result !== null && reader.result.toString()) || "")
        );

        setOpen(true);
        setCrop(undefined);
        reader.readAsDataURL(file);
      } catch (e) {
        handleError(e, "It was not possible to upload the image");
      }
    },
    [handleError, sizeLimit]
  );

  const onImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect || 1));
    },
    [aspect]
  );

  const handleCrop = useCallback(async () => {
    setCropping(true);

    try {
      const blob = await cropImage();

      if (!blob) {
        throw new FeedbackError(
          "It was not possible to crop the image due to an error"
        );
      }

      const url = URL.createObjectURL(blob);
      setImgURL(url);
      valueSetter(blob);
      setOpen(false);
    } catch (e) {
      handleError(e);
    } finally {
      setCropping(false);
    }
  }, [cropImage, handleError, valueSetter]);

  const clearImageValue = () => {
    setImgURL("");
    valueSetter(null);
  };

  return (
    <div className="flex flex-col">
      <div className="hidden">
        <input
          ref={fileInputRef}
          type="file"
          accept="image/png, image/jpeg, image/jpg, image/webp"
          onChange={onSelectFile}
          onClick={(event: any) => {
            event.target.value = null;
          }}
        />
        {Boolean(completedCrop) && <canvas ref={previewCanvasRef} />}
      </div>
      <div className={clsx("m9-image-cropper flex", className)}>
        <div className="m9-image-cropper-container relative mr-8">
          <div
            className={clsx(
              imagePreviewClassName,
              "m9-image-cropper-image-wrapper w-[128px] h-[128px] overflow-hidden flex items-center relative justify-center",
              {
                "rounded-[50vh]": circularCrop,
                "border-solid border-1 border-gray-200 rounded-2xl": !(
                  imgURL || imgUrlSrc
                ),
                "border-2 border-solid border-error": error,
                "w-[150px] h-[85px]": cropAspect === "rectangle",
              }
            )}
          >
            {imgURL || imgUrlSrc ? (
              <>
                <img
                  className={clsx("h-[inherit] w-[inherit] rounded-[inherit]")}
                  alt=""
                  src={imgURL || imgUrlSrc}
                />
                <IconButton
                  className="absolute top-2 right-2 p-0.5 bg-white bg-opacity-80 hover:bg-white hover:bg-opacity-100"
                  onClick={clearImageValue}
                  size="small"
                  disabled={disabled}
                >
                  <CloseIcon />
                </IconButton>
              </>
            ) : (
              <ImageIcon />
            )}
            {loading && (
              <div className="absolute bg-black bg-opacity-25 h-full w-full left-0 top-0">
                <Loading full />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <Typography
            variant="body2"
            className="font-semibold text-dark-text flex items-center space-x-2"
          >
            <span>{title}</span>
            <ImageUploadHelperTooltip />
          </Typography>
          {caption && (
            <Typography variant="caption2" className="grow text-gray-400">
              {caption}
            </Typography>
          )}
          <label className="mt-3" htmlFor="logo-upload">
            <Button
              disabled={disabled || loading}
              onClick={selectFile}
              variant="outlined"
              size="small"
              className="self-start w-[122px]"
            >
              Upload
            </Button>
          </label>
        </div>
      </div>
      {error && error?.message && (
        <Typography
          variant="caption2"
          className="font-semibold text-error mt-2"
        >
          {error.message}
        </Typography>
      )}
      <Modal open={open} onClose={() => setOpen(false)} title="Upload image">
        <div className="flex flex-col items-center">
          {Boolean(imgSrc) && (
            <ReactCrop
              className="max-h-[400px]"
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              circularCrop={circularCrop}
            >
              <img
                className="max-w-full"
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale})` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
          {completedCrop && (
            <Button
              disabled={cropping || !isSelectedArea}
              onClick={handleCrop}
              variant="outlined"
              size="small"
              className="w-[122px] mt-5"
            >
              Crop image
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ImageUploadWithCropper;
