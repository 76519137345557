import { AnyAction } from "redux";
import { SidebarTypeKey } from "redux/selectors";
import { UIState } from "./reducer";
import { StringKeyMap } from "types";

const mapActionsToKeys: StringKeyMap<SidebarTypeKey> = {
  "chatSidebar/openChatSidebar": SidebarTypeKey.chatSidebar,
  "projectReports/setCreateReportModuleModalParams":
    SidebarTypeKey.createReportModule,
  "projectReports/setEditReportModuleModalParams":
    SidebarTypeKey.editReportModule,
  "projectReports/setCreateOrUpdateTemplateModalParams":
    SidebarTypeKey.createOrUpdateTemplate,
};

export const sidebarMatcher: [
  (action: AnyAction) => boolean,
  (state: UIState, action: AnyAction) => void
] = [
  (action) => Object.keys(mapActionsToKeys).includes(action.type),
  (state, action) => {
    const isOpen = action.payload?.isOpen ?? true;
    if (isOpen) {
      state.openSidebarKey = mapActionsToKeys[action.type];
    }
  },
];
