import Typography from "@mui/material/Typography";
import {
  useController,
  UseControllerProps,
  FieldValues,
} from "react-hook-form";
import DatePicker from "./DatePicker";
import { DatePickerProps as MuiDatePickerProps } from "@mui/lab/DatePicker";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  showError?: boolean;
}

export const DatePickerController = <T extends FieldValues>({
  name,
  control,
  showError,
  ...props
}: Props<T> &
  Omit<MuiDatePickerProps, "value" | "onChange" | "renderInput">) => {
  const {
    field: { ref, ...field },
    fieldState,
  } = useController({
    name,
    control,
  });

  return (
    <div>
      <DatePicker
        {...field}
        {...props}
        inputRef={ref}
        error={showError && Boolean(fieldState.error)}
      />
      {showError && fieldState?.error && (
        <Typography
          variant="caption2"
          className="font-semibold text-error pt-2"
        >
          {fieldState.error.message}
        </Typography>
      )}
    </div>
  );
};

export default DatePickerController;
