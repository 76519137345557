import { Tooltip } from "@mui/material";
import Typography, { TypographyProps } from "@mui/material/Typography";
import clsx from "clsx";
import React from "react";

export interface LabeledValueProps {
  label: string;
  value: string | string[];
  labelProps?: object;
  valueProps?: React.HTMLAttributes<HTMLDivElement> &
    TypographyProps & { component?: React.ElementType };
  noWrapTitle?: boolean;
}

const LabeledValue = ({
  label,
  value,
  labelProps = {},
  valueProps = {},
  noWrapTitle,
}: LabeledValueProps) => {
  return (
    <div className="flex flex-col">
      <Typography
        variant="caption2"
        className={clsx(
          "inline-block font-bold",
          noWrapTitle && "whitespace-nowrap"
        )}
        {...labelProps}
      >
        {label}
      </Typography>
      {typeof value === "string" && (
        <Typography
          variant="body2"
          {...valueProps}
          className={clsx(
            "inline-block font-medium text-[0.875rem] leading-4 mt-1",
            valueProps?.className
          )}
        >
          {value}
        </Typography>
      )}
      {Array.isArray(value) && (
        <Tooltip
          placement="bottom-start"
          title={
            <>
              {value.map((currentValue, index) => (
                <span key={`${currentValue}-${index}`}>{currentValue}</span>
              ))}
            </>
          }
        >
          <Typography
            variant="body2"
            {...valueProps}
            className={clsx(
              "inline-block font-medium text-[0.875rem] leading-4",
              valueProps?.className
            )}
          >
            {value.join(", ")}
          </Typography>
        </Tooltip>
      )}
    </div>
  );
};

export default LabeledValue;
