import { ReactElement } from "react";
import Typography from "@mui/material/Typography";
import {
  useController,
  UseControllerProps,
  FieldValues,
} from "react-hook-form";
import FormInput from "../FormInput";
import { InputProps } from "@mui/material/Input";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  showError?: boolean;
  label?: string;
  helperText?: ReactElement;
  tooltip?: {
    id: string;
    title?: string;
    content: string | ReactElement;
  };
  required?: boolean;
}

const FormInputController = <T extends FieldValues>({
  name,
  control,
  showError,
  helperText,
  tooltip,
  required,
  ...props
}: Props<T> & InputProps) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div className="flex flex-col">
      <FormInput
        {...props}
        {...field}
        error={showError && Boolean(fieldState.error)}
        tooltip={tooltip}
        required={required}
      />
      {showError && fieldState?.error && (
        <Typography
          variant="caption2"
          className="font-semibold text-error pt-2"
        >
          {fieldState.error.message}
        </Typography>
      )}
      {helperText && (
        <Typography
          variant="caption2"
          className="font-semibold text-gray-300 pt-2"
        >
          {helperText}
        </Typography>
      )}
    </div>
  );
};

export default FormInputController;
