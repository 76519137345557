import { defaultsDeep, omit } from "lodash";
import { ReactComponent as ArrowDropDownIcon } from "assets/svg/arrow-drop-down-icon.svg";
import { Autocomplete, TextField, UseAutocompleteProps } from "@mui/material";
import { clsxm } from "application/utils";
import { Nullable, PropsWithClassName } from "types";
import { Done } from "@mui/icons-material";
import { applyBoldToMatch } from "ui/components/AutocompleteController/AutocompleteController.utils";

interface ProjectSharingRoleSelectProps extends PropsWithClassName {
  value?: Nullable<Option>;
  defaultValue?: Nullable<Option>;
  options?: Option[];
  onChange?: UseAutocompleteProps<
    Option,
    undefined,
    undefined,
    undefined
  >["onChange"];
}

export const ProjectSharingRoleSelect = ({
  options = [],
  value,
  defaultValue,
  className,
  onChange,
}: ProjectSharingRoleSelectProps) => {
  return (
    <Autocomplete
      defaultValue={defaultValue}
      onChange={onChange}
      value={value}
      className={clsxm("w-full max-w-[150px]", className)}
      classes={{
        tag: "text-gray-100",
        endAdornment: clsxm("top-[calc(50%-18px)]"),
        clearIndicator: "p-0.5",
        listbox: "p-2 space-y-2",
        option: "p-[10px] h-[40px] rounded-lg",
        input: "p-[7px_4px_7px_6px] h-4",
      }}
      clearIcon={false}
      getOptionLabel={(option) => (option as Option)?.label ?? ""}
      isOptionEqualToValue={(option, value) =>
        value === undefined || option.value === value.value
      }
      options={options}
      popupIcon={
        <ArrowDropDownIcon width="32" height="32" viewBox="0 0 32 32" />
      }
      renderInput={(params) => (
        <TextField
          {...defaultsDeep(params, {
            InputProps: {
              notched: false,
              style: { paddingRight: 68, padding: 8.5, height: 56 },
            },
          })}
          variant="outlined"
          fullWidth
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const label = option.label || "No name";

        const escapedString = inputValue.replace(
          /[-/\\^$*+?.()|[\]{}]/g,
          "\\$&"
        );
        const isActive = value?.value === option.value;

        const listItem = (
          <li {...omit(props, ["key"])} key={option.value}>
            {isActive && <Done className="w-4" />}
            <span
              className={clsxm(
                "whitespace-nowrap overflow-hidden text-ellipsis py-2",
                isActive ? "pl-2" : "pl-6"
              )}
            >
              {applyBoldToMatch(label, escapedString)}
            </span>
          </li>
        );

        return listItem;
      }}
    />
  );
};
