import { ArrowRightAlt } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { isNil, startCase, uniq } from "lodash";
import { FC, useState } from "react";
import { IAudit } from "types/audit.service";
import { FormattedValue } from "../FormattedValue";
import { useAuditParser } from "./AuditAcitivityCard.hooks";
import ReactJson from "react-json-view";
import { FormatType } from "domain/common";

interface AuditHistoryCardProps {
  audit: IAudit;
}

export const AuditActivityCard: FC<AuditHistoryCardProps> = ({ audit }) => {
  const { oldResourceValue, newResourceValue, createdAt } = audit;
  const { formatters = {}, title } = useAuditParser(audit);
  const [isExpanded, setExpanded] = useState(true);

  const newAndOldKeys = uniq([
    ...Object.keys(oldResourceValue ?? {}),
    ...Object.keys(newResourceValue ?? {}),
  ]).sort();

  const isOldResourceValueNil = isNil(oldResourceValue);
  const isNewResourceValueNil = isNil(newResourceValue);

  return (
    <div className="p-3 rounded-lg flex-col border-2 border-gray-200 border-solid shadow-sm w-full overflow-hidden">
      <div className="flex flex-col md:flex-row space-x-2 justify-between items-start">
        <div className="flex h-full space-x-2 md:mr-2 items-center flex-nowrap flex-shrink">
          <Typography className="text-gray-500" variant="caption">
            {title}
          </Typography>
        </div>
        <Typography
          className="text-gray-400 flex-shrink-0 pt-2"
          variant="caption2"
        >
          {format(parseISO(createdAt), "MMM, d yyyy HH:mm:ss")}
        </Typography>
      </div>
      {isExpanded && (
        <div className="w-full mb-4">
          {newAndOldKeys?.map((currentKey) => {
            const formatter = (value: any) => {
              if (isNil(value)) return "Empty";
              if (typeof formatters[currentKey] === "function")
                return (formatters[currentKey] as any)(value);
              if (typeof formatters[currentKey] === "string")
                return (
                  <FormattedValue
                    value={value}
                    formatType={formatters[currentKey] as FormatType}
                  />
                );

              switch (typeof value) {
                case "object":
                  return (
                    <ReactJson
                      src={value}
                      displayObjectSize={false}
                      displayDataTypes={false}
                      quotesOnKeys={false}
                      enableClipboard={false}
                      {...({ displayArrayKey: false } as any)}
                    />
                  );
                case "string":
                  return value;
                default:
                  return JSON.stringify(value, null, 2);
              }
            };

            return (
              <div
                key={currentKey}
                className="mt-3 bg-gray-150 rounded-lg p-3 w-full overflow-auto"
              >
                <Typography className="mb-4 text-gray-400" variant="caption2">
                  {startCase(currentKey).replace(/fund/gi, "Deal")}
                </Typography>
                <div className="flex pl-2 items-center">
                  {!isOldResourceValueNil && (
                    <div className={isNewResourceValueNil ? "" : "max-w-[50%]"}>
                      {formatter(oldResourceValue?.[currentKey])}
                    </div>
                  )}
                  {!(isOldResourceValueNil || isNewResourceValueNil) && (
                    <div className="mx-2">
                      <ArrowRightAlt />
                    </div>
                  )}
                  {!isNewResourceValueNil && (
                    <div className={isOldResourceValueNil ? "" : "max-w-[50%]"}>
                      {formatter(newResourceValue?.[currentKey])}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div
        className="w-full flex justify-center items-center -mb-3 cursor-pointer"
        onClick={() => setExpanded(!isExpanded)}
      >
        <Typography
          className="text-gray-300 font-semibold"
          component="span"
          variant="caption2"
        >
          {isExpanded ? "Click to hide" : "Click to expand"}
        </Typography>
      </div>
    </div>
  );
};
