import projectAssistantService from "services/projectAssistant.service";
import useSWR, { SWRConfiguration } from "swr";
import { IDetailedThread } from "types/assistant.service";

export const getProjectAssistantThreadSWRKey = (
  projectId: string,
  threadId: string
) => `Project/${projectId}/assistant/threads/${threadId}`;

export const useProjectAssistantThread = (
  projectId: string | undefined,
  threadId: string | undefined,
  config?: SWRConfiguration<IDetailedThread>
) => {
  return useSWR(
    projectId &&
      threadId &&
      getProjectAssistantThreadSWRKey(projectId, threadId),
    () => {
      return projectAssistantService.getThreadById(projectId!, threadId!);
    },
    config
  );
};
