import { RemoveCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { v4 as uuid } from "uuid";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import dealService from "services/deal.service";
import {
  CardSection,
  DealEditPersonProps,
  ImageUploadWithCropper,
} from "ui/components";
import {
  BasicInfoSection,
  defaultValues as defaultPersonValues,
  OtherDealsSection,
  FormInputs as PersonInputs,
} from "ui/components/DealEditPerson/DealEditPerson.form";
import { useErrorHandler, useOrganization } from "ui/hooks";
import { ExpandableWatchableSectionProps } from "ui/pages/Form";

export interface FormInputs {
  people: PersonInputs[];
}

export const defaultValues: FormInputs = {
  people: [defaultPersonValues],
};

export const DealEditSponsorSectionItem: FC<
  ExpandableWatchableSectionProps<FormInputs> & {
    index: number;
    nameVariants: { singularVariant: string; pluralVariant: string };
  } & Pick<
      DealEditPersonProps,
      "BasicInfoSectionProps" | "OtherDealsSectionProps" | "imageInputTitle"
    >
> = ({
  control,
  index,
  onItemRemove,
  watch,
  BasicInfoSectionProps,
  OtherDealsSectionProps,
  imageInputTitle,
  setValue,
  nameVariants,
  reset,
}) => {
  const imageUrl = watch(`people.${index}.imageUrl`);
  const nameValue = watch(`people.${index}.name`);
  const organizationId = (nameValue as Option)?.value;
  const [uploadingFile, setUploadingFile] = useState(false);
  const { handleError } = useErrorHandler();
  const { append, remove, fields } = useFieldArray({
    control,
    name: `people.${index}.otherDeals`,
  });
  const { data: organization } = useOrganization(organizationId);
  const isOrganization = useMemo(
    () => Boolean(organizationId),
    [organizationId]
  );

  useEffect(() => {
    if (!organization) return;
    setValue?.(`people.${index}.url`, organization?.website);
    setValue?.(`people.${index}.imageUrl`, organization?.imageUrl);
    setValue?.(`people.${index}.description`, organization.longDescription);
  }, [index, organization, setValue]);

  const handleImageUpload = useCallback(async (file: File) => {
    setUploadingFile(true);

    try {
      const formData = new FormData();
      formData.append("file", file, `${uuid()}.png`);
      const { imageUrl } = await dealService.uploadDealImage(formData);

      return imageUrl;
    } finally {
      setUploadingFile(false);
    }
  }, []);

  return (
    <CardSection
      title={`${nameVariants.singularVariant} #${index + 1}`}
      className="flex flex-col space-y-8"
      actions={
        <Button
          className="mr-2"
          variant="text"
          size="small"
          onClick={() => onItemRemove(index)}
          startIcon={<RemoveCircle />}
        >
          Remove
        </Button>
      }
    >
      <Controller
        name={`people.${index}.imageUrl`}
        control={control}
        render={({ field }) => (
          <ImageUploadWithCropper
            caption="We recommend an image of at least 400x400."
            title={imageInputTitle}
            loading={uploadingFile}
            valueSetter={async (val: Blob | null) => {
              if (!val) {
                return field.onChange({ target: { value: null } });
              }

              try {
                const url = await handleImageUpload(val as File);
                field.onChange({ target: { value: url } });
              } catch (e) {
                handleError(e, "It was not possible to upload the image.");
              }
            }}
            imgUrlSrc={imageUrl}
            circularCrop
          />
        )}
      />
      <BasicInfoSection
        inputNames={{
          name: `people.${index}.name`,
          description: `people.${index}.description`,
          url: `people.${index}.url`,
        }}
        isOrganization={isOrganization}
        {...BasicInfoSectionProps}
        control={control}
      />
      {!organizationId && (
        <OtherDealsSection
          name={`people.${index}.otherDeals`}
          control={control}
          fields={fields}
          onItemIncrement={() => append(defaultPersonValues?.otherDeals[0])}
          onItemRemove={(index?: number) => remove(index)}
          {...OtherDealsSectionProps}
        />
      )}
    </CardSection>
  );
};
