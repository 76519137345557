import { RegistrationProgressEnum, NDAStatusEnum } from "domain/authorization";
import { AnyAction } from "redux";
import { ActionType } from "./actions";
import { merge } from "lodash";

export interface AuthState {
  loginRedirect: boolean;
  authorized: boolean;
  user: any;
  error: string;
  postConfimationDone: boolean;
  onboarding: string;
  ndaStatusStep: RegistrationProgressEnum | undefined;
  ndaStatus: NDAStatusEnum;
  intercomUserHash: string;
}

export const initialAuthState = {
  authorized: false,
  user: null,
  error: "",
  loading: false,
  postConfimationDone: false,
  onboarding: "",
  ndaStatusStep: undefined,
  ndaStatus: NDAStatusEnum.Unknown,
  loginRedirect: false,
  intercomUserHash: "",
};

const reducer = (
  state: AuthState = initialAuthState,
  action: AnyAction
): AuthState => {
  switch (action.type) {
    case ActionType.SetOnboarding: {
      return { ..._getCommonState(state), onboarding: action.payload };
    }
    case ActionType.SignInPending:
      return { ..._getCommonState(state) };
    case ActionType.SignInSuccess: {
      return {
        ..._getCommonState(state),
        ...action.payload,
        authorized: true,
      };
    }
    case ActionType.SignInFail: {
      return {
        ..._getCommonState(state),
        authorized: false,
        user: null,
        error: action.payload,
      };
    }
    case ActionType.SetLoginRedirect: {
      return {
        ..._getCommonState(state),
        loginRedirect: action.payload,
      };
    }
    case ActionType.SetNDAStatus: {
      return {
        ..._getCommonState(state),
        ndaStatusStep: action.payload.ndaStatusStep,
        ndaStatus: action.payload.ndaStatus,
      };
    }
    case ActionType.SetIntercomUserHash: {
      return {
        ..._getCommonState(state),
        intercomUserHash: action.payload,
      };
    }
    case ActionType.UpdateUserAttributes: {
      return {
        ..._getCommonState(state),
        user: merge({}, _getCommonState(state).user, {
          attributes: action.payload,
        }),
      };
    }
    default:
      return state;
  }
};

const _getCommonState = (state: AuthState) => ({
  ...state,
  error: "",
  loading: false,
  loginRedirect: false,
});

export default reducer;
