import { vestResolver } from "@hookform/resolvers/vest";
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid } from "@mui/material";
import { CampaignStatus } from "domain/campaign";
import { defaultsDeep, isUndefined, sortBy } from "lodash";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import campaignService from "services/campaign.service";
import lookupService from "services/lookup.service";
import useSWR from "swr";
import { IMediaItemPreview } from "types/media.service";
import * as paths from "ui/Router/paths";
import {
  FormInputController,
  FormInputWrapper,
  Loading,
  SectionTitle,
} from "ui/components";
import { MarkdownInputController } from "ui/components/MarkdownInput";
import { useErrorHandler, useSyndicationLayout } from "ui/hooks";
import {
  useAllContactLists,
  useCampaignQuota,
} from "ui/hooks/Deal/useDealCommunication";
import { useAllMedia } from "ui/hooks/Media/useAllMedia";
import {
  ICampaign,
  ICampaignContactListByPartner,
  ICampaignRequest,
} from "../../../types/campaign.service";
import {
  DealCampaignStepEnum,
  DealEditCampaignFormLayout,
  useDealCampaignFormUtils,
} from "../DealEditCampaignForm";
import {
  AttachContentList,
  QuotaCalculator,
  ScrollableListSearch,
} from "./CampaignCreate.components";
import { getSchema } from "./CampaignCreate.validation";
import CampaignCreateListModal from "./CampaignCreateListModal";
import { delayAsync } from "application/utils";
import { ShowOnEmerge9 } from "ui/components/PlatformSpecificElement";

export interface FormInputs
  extends Partial<
    Omit<
      ICampaignRequest,
      "contactListIds" | "excludedContactListIds" | "scheduled" | "sendAt"
    >
  > {
  contactListIds: Record<string, boolean>;
  excludedContactListIds: Record<string, boolean>;
}

export const defaultValues: FormInputs = {
  title: "",
  plainContent: "",
  htmlContent: "",
  subject: "",
  message: "",
  createdAt: "",
  updatedAt: "",
  promotedFundIds: [],
  promotedMediaIds: [],
  contactListIds: {},
  excludedContactListIds: {},
};

interface CreateOrEditCampaignProps {
  dealId?: string;
  title: string;
  existingData?: ICampaign;
  isLoading?: boolean;
}

export const CreateOrEditDealCampaignForm: FC<CreateOrEditCampaignProps> = ({
  dealId,
  title,
  existingData,
  isLoading,
}) => {
  const isDelivered = useMemo(
    () => existingData?.status === CampaignStatus.Delivered,
    [existingData?.status]
  );
  const { navigateToNextRoute, parsedPreviousRoute } = useDealCampaignFormUtils(
    DealCampaignStepEnum.Create
  );
  const { enqueueSnackbar } = useSnackbar();
  const { isSyndicate } = useSyndicationLayout();
  const { handleError } = useErrorHandler();
  const [isSubmitting, setSubmitting] = useState(false);
  const [contactLists, setContactLists] = useState<
    ICampaignContactListByPartner[]
  >([]);
  const [isInitialized, setInitialized] = useState(false);
  const navigate = useNavigate();

  const { reset, control, handleSubmit, formState, watch, trigger } = useForm({
    defaultValues: defaultValues,
    resolver: isDelivered ? undefined : vestResolver(getSchema(contactLists)),
  });

  const promotedDealIdsValue = watch("promotedFundIds");
  const contactListIdsValue = watch("contactListIds");

  const {
    data: contactListSWR,
    isValidating,
    mutate: mutateContactLists,
  } = useAllContactLists(promotedDealIdsValue, {
    onErrorRetry: async (error, key, config, revalidate, revalidateOps) => {
      if (revalidateOps.retryCount < (config.errorRetryCount || 3)) {
        await delayAsync(config.errorRetryInterval || 5000);

        return revalidate(revalidateOps);
      }

      handleError(
        error,
        "It was not possible to get the contacts list. Please try again later.",
        "Unable to get Contact lists"
      );
      navigate(-1);
    },
  });

  useEffect(() => {
    if (promotedDealIdsValue && contactListIdsValue) {
      trigger("promotedFundIds", { shouldFocus: false });
    }
  }, [contactListIdsValue, promotedDealIdsValue, trigger]);

  useEffect(() => {
    if (contactListSWR && contactListSWR.length > 0) {
      setInitialized(true);
      return setContactLists(contactListSWR);
    }
  }, [contactListSWR]);

  const selectedContactLists = watch("contactListIds");

  const indexedContactListsRecipientAmount = useMemo(() => {
    return (
      contactLists?.reduce((out, item) => {
        for (const list of item.contactLists) {
          out[list.id] = list.totalOfRecipients;
        }

        return out;
      }, {} as Record<string, number>) || {}
    );
  }, [contactLists]);

  const selectedAmount = useMemo(() => {
    const selectedItems = Object.keys(selectedContactLists).filter(
      (key) => selectedContactLists[key]
    );

    return selectedItems.reduce((out, key) => {
      return indexedContactListsRecipientAmount[key] + out;
    }, 0);
  }, [indexedContactListsRecipientAmount, selectedContactLists]);

  const { data: quotaData } = useCampaignQuota(undefined);

  const { data: dealData, error: dealError } = useSWR(
    "/Lookup/deals",
    async () => {
      const { response } = await lookupService.getLookupDeals();

      return response;
    }
  );
  const { data: podcastData, error: podcastError } = useAllMedia();

  const isDealLoading = [dealData, dealError].every(isUndefined);
  const isPodcastLoading = [podcastData, podcastError].every(isUndefined);

  const sortedPodcasts = useMemo(() => {
    if (!podcastData) return [];

    return sortBy<IMediaItemPreview>(
      podcastData,
      (podcast) => `${podcast.hasPublicInfo ? 0 : 1}-${podcast.title}`
    );
  }, [podcastData]);

  const sortedDeals = useMemo(() => {
    if (!dealData) return [];

    return sortBy(dealData, (deal) => deal?.fundName?.toLowerCase());
  }, [dealData]);

  const [isCreateListModalOpen, setIsCreateListModalOpen] = useState(false);

  useEffect(() => {
    if (existingData && !formState.isDirty) {
      const newValue: Subset<FormInputs> = {
        ...existingData,
        contactListIds:
          existingData?.contactLists?.reduce(
            (out, contactList) => ({ ...out, [contactList.id]: true }),
            {}
          ) || [],
        excludedContactListIds:
          existingData?.excludedContactLists?.reduce(
            (out, contactList) => ({
              ...out,
              [contactList.id]: true,
            }),
            {}
          ) || [],
      };

      const result = defaultsDeep(newValue, defaultValues);

      reset(result, { keepDirty: true });
    }
  }, [existingData, formState.isDirty, handleError, reset]);

  const getCreatePayload = useCallback(
    (submitData: FormInputs): Partial<ICampaign> => {
      return {
        contactListIds: Object.keys(submitData.contactListIds).filter(
          (key) => submitData.contactListIds[key]
        ),
        excludedContactListIds: Object.keys(
          submitData.excludedContactListIds
        ).filter((key) => submitData.excludedContactListIds[key]),
        title: submitData.title,
        message: submitData.message,
        subject: submitData.subject,
        fundId: dealId,
        promotedFundIds: submitData.promotedFundIds,
        promotedMediaIds: submitData.promotedMediaIds,
      } as Partial<ICampaign>;
    },
    [dealId]
  );

  const onSubmit: SubmitHandler<any> = async (submitData: FormInputs) => {
    setSubmitting(true);

    if (isDelivered && existingData)
      return navigateToNextRoute(existingData.id);

    try {
      const payload = getCreatePayload(submitData);
      const isUpdate = Boolean(existingData?.id);

      const newCampaign = await (isUpdate
        ? campaignService.updateCampaignById(existingData?.id as string, {
            ...existingData,
            ...payload,
          })
        : campaignService.createCampaign(payload));

      enqueueSnackbar(
        `The campaign has been ${isUpdate ? "updated" : "created"}.`,
        {
          title: `Campaign ${isUpdate ? "updated" : "created"}`,
          variant: "success",
        }
      );

      navigateToNextRoute(newCampaign.id);
    } catch (e) {
      handleError(e, "It was not possible to create the campaign.");
    } finally {
      setSubmitting(false);
    }
  };

  const isLimitExceeded = useMemo(() => {
    if (!isSyndicate) {
      return false;
    }

    return selectedAmount > (quotaData?.available || 0);
  }, [isSyndicate, quotaData?.available, selectedAmount]);

  const onReset = useCallback(() => {
    reset(defaultValues);
  }, [reset]);

  const onCreateContactList = () => {
    setIsCreateListModalOpen(true);
  };

  if (isLoading || !isInitialized) {
    return <Loading full />;
  }

  return (
    <>
      <DealEditCampaignFormLayout
        currentStep={DealCampaignStepEnum.Create}
        previousStepRoute={parsedPreviousRoute}
        title={title}
        onReset={onReset}
        isSubmitting={isSubmitting || isValidating}
        onSubmit={handleSubmit(onSubmit)}
        submitLabel={isDelivered ? "Check preview" : undefined}
        limitExceeded={isLimitExceeded}
        breadcrumbItems={[
          {
            label: title,
            isLast: true,
          },
        ]}
      >
        <div className="flex flex-col space-y-8">
          <SectionTitle>Campaign settings</SectionTitle>
          <div>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <div>
                  <ScrollableListSearch
                    label="Recipients"
                    disabled={isDelivered}
                    loading={isDealLoading}
                    control={control}
                    placeholder="Recipients"
                    name="contactListIds"
                    data={contactLists}
                    indexedContactListsRecipientAmount={
                      indexedContactListsRecipientAmount
                    }
                  />

                  <div className="flex mb-5 mt-5">
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<AddIcon />}
                      className="h-[32px]"
                      onClick={onCreateContactList}
                    >
                      Create new contact list
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div>
                  <ScrollableListSearch
                    label="Excluded Recipients"
                    disabled={isDelivered}
                    loading={isDealLoading}
                    control={control}
                    placeholder="Excluded Recipients"
                    name="excludedContactListIds"
                    data={contactLists}
                    indexedContactListsRecipientAmount={
                      indexedContactListsRecipientAmount
                    }
                  />
                </div>
              </Grid>
            </Grid>
            <div className="flex space-y-4">
              <Link to={"/" + paths.campaignContactLists}>
                <Button variant="outlined" size="small" className="h-[32px]">
                  Manage lists
                </Button>
              </Link>
            </div>
          </div>
          {isSyndicate && quotaData && (
            <div>
              <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                  <QuotaCalculator
                    quota={quotaData}
                    selectedAmount={selectedAmount}
                  />
                </Grid>
              </Grid>
            </div>
          )}
          <ShowOnEmerge9>
            <FormInputWrapper label="Attach content">
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <AttachContentList
                    disabled={isDelivered}
                    loading={isDealLoading}
                    control={control}
                    placeholder="Attach deals"
                    name="promotedFundIds"
                    data={sortedDeals}
                    getLabel={(data) => data.fundName}
                    getValue={(data) => data.fundId}
                    title="Attach select deals"
                  />
                </Grid>
                {!isSyndicate && (
                  <Grid item xs={12} md={4}>
                    <AttachContentList
                      disabled={isDelivered}
                      loading={isPodcastLoading}
                      control={control}
                      placeholder="Attach podcasts"
                      name="promotedMediaIds"
                      data={sortedPodcasts}
                      getLabel={(data) =>
                        `${data.title}${data.hasPublicInfo ? "" : " [Private]"}`
                      }
                      getValue={(data) => data.id}
                      getDisabled={(data) => !data.hasPublicInfo}
                      title="Attach select podcasts"
                    />
                  </Grid>
                )}
              </Grid>
            </FormInputWrapper>
          </ShowOnEmerge9>

          <div className="max-w-[688px] w-full">
            <FormInputController
              name="title"
              control={control}
              label="Campaign title"
              placeholder="Add title"
              showError
              readOnly={isDelivered}
            />
          </div>
          <div className="max-w-[688px] w-full">
            <FormInputController
              name="subject"
              control={control}
              label="Email subject"
              placeholder="Add subject"
              showError
              readOnly={isDelivered}
            />
          </div>

          <div className="max-w-[988px] w-full h-[450px]">
            <MarkdownInputController
              control={control}
              name="message"
              label="Message"
              placeholder="Add message"
              readOnly={isDelivered}
              className="h-full"
            />
          </div>
        </div>
      </DealEditCampaignFormLayout>
      <CampaignCreateListModal
        isExclusionList={false}
        isOpen={isCreateListModalOpen}
        handleClose={() => setIsCreateListModalOpen(false)}
        onSuccess={() => mutateContactLists()}
      />
    </>
  );
};
