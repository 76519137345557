import { lazy } from "react";
import { Route } from "react-router-dom";
import AdminManageWrapper from "ui/pages/AdminManage/AdminManage.wrapper";
import { FeatureFlagId } from "domain/featureFlag";
import { config } from "config";
import { RestrictedByGroupOutlet } from "ui/Router/guards/RestrictedByGroupOutlet";
import { RestrictedByFeatureFlagOutlet } from "ui/Router/guards/RestrictedByFeatureFlagOutlet";
import { partnerRoutes } from "./bullpenPartnerRoutes";

const AdminManageUsers = lazy(
  () => import("ui/pages/AdminManage/AdminManageUsers")
);

const AdminManageProjects = lazy(
  () => import("ui/pages/AdminManage/AdminManageProjects")
);

const AdminManageDevActions = lazy(
  () => import("ui/pages/AdminManage/AdminDevActions")
);

const SyndicationManagementInviteList = lazy(
  () => import("ui/pages/SyndicationManagement/SyndicationManagementInviteList")
);

const SyndicationManagementInviteConversions = lazy(
  () =>
    import(
      "ui/pages/SyndicationManagement/SyndicationManagementInviteConversions"
    )
);

const SyndicationManagementCreateInvite = lazy(
  () =>
    import("ui/pages/SyndicationManagement/SyndicationManagementCreateInvite")
);

const SyndicationManagementPartnerList = lazy(
  () =>
    import("ui/pages/SyndicationManagement/SyndicationManagementPartnerList")
);

const AdminManageFlags = lazy(
  () => import("ui/pages/AdminManage/AdminManageFlags")
);

const AdminManageUser = lazy(
  () => import("ui/pages/AdminManage/AdminManageUser")
);

const AdminManageSyndicationUsers = lazy(
  () => import("ui/pages/AdminManage/AdminManageSyndicationUsers")
);

const AdminManageSyndicationCampaigns = lazy(
  () => import("ui/pages/AdminManage/AdminManageSyndicationCampaigns")
);

export const bullpenAdminManagementRoutes = [
  <Route
    key="adminManagementRoutes"
    path={"manage/"}
    element={<RestrictedByGroupOutlet admin syndicationAdmin />}
  >
    <Route element={<AdminManageWrapper />}>
      <Route path="users" element={<AdminManageUsers />} />

      <Route
        element={
          config.env === "prod" ? (
            <RestrictedByGroupOutlet developer />
          ) : (
            <RestrictedByGroupOutlet admin />
          )
        }
      >
        <Route
          element={
            <RestrictedByFeatureFlagOutlet
              featureFlag={FeatureFlagId.Projects}
            />
          }
        >
          <Route path="projects" element={<AdminManageProjects />} />
        </Route>
      </Route>

      <Route element={<RestrictedByGroupOutlet developer />}>
        <Route path="dev-actions" element={<AdminManageDevActions />} />

        <Route
          path="syndication-campaigns"
          element={<AdminManageSyndicationCampaigns />}
        />
      </Route>

      <Route
        path="syndication-users"
        element={<AdminManageSyndicationUsers />}
      />

      <Route path="partners" element={<SyndicationManagementPartnerList />} />

      <Route path="flags" element={<AdminManageFlags />} />

      <Route path="invites" element={<SyndicationManagementInviteList />} />
    </Route>

    {partnerRoutes}

    <Route path="invite/:inviteCode">
      <Route
        path="conversions"
        element={<SyndicationManagementInviteConversions />}
      />
    </Route>

    <Route path="invite">
      <Route path="create" element={<SyndicationManagementCreateInvite />} />
      <Route
        path="edit/:inviteCode"
        element={<SyndicationManagementCreateInvite />}
      />
    </Route>

    <Route path="user/:id" element={<AdminManageUser />} />
  </Route>,
];
