import {
  OnboardingUserData,
  UserProfileResponse,
  GetUserProfileResponse,
  IFeaturedInvestor,
  SignatureDocumentResponsePayload,
  SignatureDocumentSessionResponsePayload,
  IRegistrationSteps,
  UserInterestPreferences,
  IntercomUserHash,
} from "types";
import { apiClient } from "./clients";

class UserService {
  private readonly basePath = "/User";

  async promoteUserToInvestor(userName: string, tenantId: string) {
    return apiClient.put(`/Group/investor/${userName}`, null, {
      headers: { "partner-id": tenantId },
    });
  }

  async addMeToUserGroup() {
    return apiClient.put("/Group/user/me", {});
  }

  async getOnboardingUserData(): Promise<OnboardingUserData> {
    const { data } = await apiClient.get("/User/onboarding");
    return data?.response;
  }

  async putOnboardingUserData(data: OnboardingUserData) {
    return apiClient.put("/User/onboarding", data);
  }

  async getUserInterestsData(): Promise<
    GenericResponse<UserInterestPreferences>
  > {
    const { data } = await apiClient.get(
      this.basePath + "/preference/interests"
    );
    return data?.response;
  }

  async putUserInterestsData(data: UserInterestPreferences) {
    return apiClient.put(this.basePath + "/preference/interests", data);
  }

  async addMeToFeaturedInvestorGroup() {
    return apiClient.put("/Group/featured-investor/me", {});
  }

  async getGroupFeaturedInvestor(): Promise<IFeaturedInvestor[]> {
    const { data } = await apiClient.get("/Group/featured-investor");

    return data;
  }

  async followUser(username: string): Promise<void> {
    return apiClient.post(`/User/${username}/follow`);
  }

  async unfollowUser(username: string): Promise<void> {
    return apiClient.delete(`/User/${username}/follow`);
  }

  async updateUserImage(formData: FormData): Promise<string> {
    const { data } = await apiClient.put(this.basePath + "/image", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  }

  async getUserProfile(
    config: object = {}
  ): Promise<GenericResponse<GetUserProfileResponse>> {
    const { data } = await apiClient.get(this.basePath + "/profile/me", {
      ...config,
    });

    return data;
  }

  async getUserProfileByCustomUsername(
    customUsername: string,
    config: object = {}
  ): Promise<GenericResponse<GetUserProfileResponse>> {
    const { data } = await apiClient.get(
      this.basePath + `/profile/${customUsername}`,
      {
        ...config,
      }
    );

    return data;
  }

  async updateUserProfile(payload: any): Promise<UserProfileResponse> {
    const { data } = await apiClient.put(this.basePath + "/profile", payload, {
      headers: { "Content-Type": "application/json" },
    });

    return data;
  }

  async uploadUserImage(formData: FormData): Promise<{ imageUrl: string }> {
    const { data: imageUrl } = await apiClient.post(
      `${this.basePath}/upload-image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { imageUrl };
  }

  async getSignatureDocument(
    config: object | undefined = {}
  ): Promise<GenericResponse<SignatureDocumentResponsePayload>> {
    const { data } = await apiClient.get(
      this.basePath + "/profile/signature-document",
      config
    );

    return data;
  }

  async postSignatureDocument(): Promise<
    GenericResponse<SignatureDocumentResponsePayload>
  > {
    const { data } = await apiClient.post(
      this.basePath + `/profile/signature-document`
    );

    return data;
  }

  async getSignatureDocumentSession(): Promise<
    GenericResponse<SignatureDocumentSessionResponsePayload>
  > {
    const { data } = await apiClient.get(
      this.basePath + "/profile/signature-document/session"
    );

    return data;
  }

  async getSignatureDocumentDownload(): Promise<Blob> {
    const { data } = await apiClient.get(
      this.basePath + "/profile/signature-document/download",
      {
        responseType: "blob",
      }
    );

    return data;
  }

  async getUserOnboardingSteps(): Promise<GenericResponse<IRegistrationSteps>> {
    const { data } = await apiClient.get(
      this.basePath + "/profile/registration-steps"
    );

    return data;
  }

  async putUserOnboardingSteps(
    payload: RequireAtLeastOne<IRegistrationSteps>
  ): Promise<GenericResponse<IRegistrationSteps>> {
    const { data } = await apiClient.put(
      this.basePath + "/profile/registration-steps",
      payload
    );

    return data;
  }

  async getIntercomUserHash(): Promise<GenericResponse<IntercomUserHash>> {
    const { data } = await apiClient.get(this.basePath + "/intercom");
    return data;
  }
}

export default new UserService();
